import React from 'react';
import './App.css';
import {Button, InputLabel, Snackbar, TextField} from '@mui/material';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as Common from './common';
import * as AxiosApi from './c2-api-admin/api'


function GetComponents(prefix: string, toProcess : any){
    let toProcessComponents : any[] = [];
    const toProcessData : any = {};
    Object.keys(toProcess).forEach((toProcessKey) => {toProcessData[toProcessKey] = toProcess[toProcessKey]});
    if(toProcessData?.Reason){
        try{
            const parsed = JSON.parse(toProcess.Reason);
            delete toProcessData.Reason;
            if(parsed){
                Object.keys(parsed).forEach((toProcessKey) => {toProcessData[toProcessKey] = parsed[toProcessKey]});
            }
        }
        catch(e){
            // legacy data will fail to parse
        }
    }
    Object.keys(toProcessData).forEach((toProcessKey) => {
        if (typeof toProcessData[toProcessKey] == 'object'){
            toProcessComponents = [...toProcessComponents, ...GetComponents(`${toProcessKey}:`, toProcessData[toProcessKey])];
        }
        else{
            toProcessComponents.push(<TextField style={{width: '100%'}} disabled={true} label={`${prefix}${toProcessKey}`} value={toProcessData[toProcessKey]}/>);
        }
    });
    return(toProcessComponents);
}

export function RecursiveTreeView(data: any, name: string) {
    let i = 0;
    let expandedNodes: any[] = [];

    const generateDisplayObject = (data: any, name: string) => {
        let child: any = {name: name, id: i++, children: []};
        if (typeof data == 'object') {
            expandedNodes.push(child.id);
            Object.keys(data).forEach((element) => {
                child.children.push(
                    generateDisplayObject(data[element], element)
                );
            });
        } else if (Array.isArray(data)) {
            expandedNodes.push(child.id);
            for (let index = 0; index < data.length; index++) {
                child.children.push(
                    generateDisplayObject(data[index], '' + index)
                );
            }
        } else {
            child.name += ': ' + data;
        }
        return child;
    };
    let displayObject = generateDisplayObject(data, name);

    const renderTree = (nodes: any) => (
        <TreeItem
            // @ts-ignore
            style={{expanded: true, textAlign: 'left'}}
            key={nodes.id}
            nodeId={nodes.id}
            label={nodes.name}
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node: any) => renderTree(node))
                : null}
        </TreeItem>
    );

    return (
        <TreeView
            defaultExpanded={expandedNodes}
            style={{
                alignContent: 'left',
                height: 110,
                flexGrow: 1,
                maxWidth: 400,
            }}
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
        >
            {renderTree(displayObject)}
        </TreeView>
    );
}


type KickBanProps = {
    accountId: string;
    token: string;
    titleId: string;
    mainComponent: any;
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[];
};

type KickBanState = {
    accountId: string;
    token: string;
    titleId: string;
    accountInfo: any;
    banReason: string;
    grants: any;
    ban: any;
    mute: any;
    playFabId: string;
    eosId: string;
    retrievePlayFabId: string;
    displayName: string;
    reason: string;
    duration: number;
    muteReason: '';
    muteDuration: number;
    searchResults: any[];
    snackbarMessage: string | null;
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[];
    account?: AxiosApi.InlineResponseDefault3;
    ipAddress?: string;
    ipAddressSearch?: string;
    lastPlayFabId?: string;
    mainComponent: any;
    inFlight: boolean;
};


function getEmptyState(): KickBanState {
    return ({
        accountInfo: {},
        grants: {},
        ban: {},
        banReason: '',
        mute: {},
        accountId: '',
        token: '',
        titleId: '',
        playFabId: '',
        eosId: '',
        retrievePlayFabId: '',
        displayName: '',
        reason: '',
        duration: 24,
        muteReason: '',
        muteDuration: 24,
        searchResults: [],
        snackbarMessage: null,
        mainComponent: '',
        inFlight: false,
        ipBans: [],
        account: undefined,
        ipAddress: undefined,
        lastPlayFabId: undefined
    });
}

class KickBan extends React.Component<KickBanProps, KickBanState> {
    errorMessage: string = '';

    constructor(props: KickBanProps) {
        super(props);
        this.state = {...getEmptyState(), ...props};
    }

    componentWillReceiveProps(props: KickBanProps, nextContext: any) {
        this.setState({...getEmptyState(), ...props});
    }

    async webQuery<QueryResultType>(action: string, query : () => Promise<QueryResultType>, onSuccess: (result: QueryResultType) => void){
        const webAsync = async () => {
            this.errorMessage = '';
            this.setState({inFlight : true});

            try {
                const result = ((await query()) as unknown as {Success: boolean, Error: string});
                if (result.Success) {
                    this.setState({snackbarMessage: `${action} succeeded`});
                    onSuccess(result as unknown as QueryResultType);
                } else {
                    this.setState({
                        snackbarMessage:
                        `${action} failed: ${JSON.stringify(result.Error ?? result)}` 
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage: `${action} failed: ${JSON.stringify(error)}`
                });
            } finally {
                this.setState({inFlight : false});
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: `attempting ${action}`});
    }

    kick = () => {
        this.webQuery('kick', (async () =>
            {
                return (await Common.Api.kickPost(this.state.token, {
                    TitleId: this.state.titleId,
                    PlayFabId: this.state.playFabId,
                    Duration: this.state.duration,
                    Reason: this.state.banReason === 'other' ? this.state.reason : this.state.banReason})).data;
            }),
            (() => {
                this.getAccount(undefined);
            }));
    };

    unBan = () => {
        this.webQuery('unban', (async () =>
        {
            return (await Common.Api.unBanPost(this.state.token, {
                TitleId: this.state.titleId,
                PlayFabId: this.state.playFabId,
            })).data;
        }),
        (() => {
            this.getAccount(undefined);
        }));
    }

    unMute = () => {
        this.webQuery('unMute', (async () =>
        {
            return (await Common.Api.unMutePost(this.state.token, {
                TitleId: this.state.titleId,
                PlayFabId: this.state.playFabId,
            })).data;
        }),
        (() => {
            this.getAccount(undefined);
        }));
    }
    
    banIp = () => {
        this.webQuery('banIpAddress', (async () =>
        {
            return (await Common.Api.banIpAddressPost(this.state.token, {
                TitleId: this.state.titleId,
                IpAddress: this.state.ipAddress as string,
                PlayFabId: this.state.lastPlayFabId as string,
                Notes: ' '
            })).data;
        }),
        (() => {
            const newIpBans : AxiosApi.InlineResponseDefault1DataIpBans[] = [...this.state.ipBans,
                {TitleId: this.state.titleId, Admin: '', IpAddress: this.state.ipAddress as string, Notes: ' ', PlayFabIds: this.state.searchResults.length, Date: `${new Date()}`}];
            this.setState({ipBans : newIpBans});
            this.state.mainComponent.setState({ipBans : newIpBans});
        }));
    }

    unBanIp = () => {
        this.webQuery('banIpAddress', (async () =>
        {
            return (await Common.Api.unBanIpAddressPost(this.state.token, {
                TitleId: this.state.titleId,
                IpAddress: this.state.ipAddress as string
            })).data;
        }),
        (() => {
            const newIpBans = this.state.ipBans.filter((element: any) => (element.IpAddress !== this.state.ipAddress && element.TitleId !== this.state.titleId));
            this.setState({ipBans : newIpBans});
            this.state.mainComponent.setState({ipBans : newIpBans});
        }));
    }

    mute = () => {
        this.webQuery('mute', (async () =>
        {
            return (await Common.Api.mutePost(this.state.token, {
                TitleId: this.state.titleId,
                PlayFabId: this.state.playFabId,
                Duration: this.state.muteDuration,
                Reason: this.state.muteReason,
            })).data;
        }),
        (() => {
            this.getAccount(undefined);
        }));
    }

    search = () => {
        this.setState({ipAddress: undefined, lastPlayFabId : undefined});
        this.webQuery('search', (async () =>
        {
            return (await Common.Api.searchPost(this.state.token, {
                TitleId: this.state.titleId,
                DisplayName: this.state.displayName,
            })).data;
        }),
        ((result) => {
            this.setState({
                searchResults: result.Data.Results,
            });
        }));
    }

    searchAccountsWithIpAddress = () => {
        this.webQuery('searchAccountsWithIpAddress', (async () =>
        {
            return (await Common.Api.searchPost(this.state.token, {
                TitleId: this.state.titleId,
                IpAddress: this.state.ipAddressSearch,
            })).data;
        }),
        ((result) => {
            this.setState({
                ipAddress: this.state.ipAddressSearch,
                playFabId: '',
                searchResults: result.Data.Results,
                lastPlayFabId: this.state.lastPlayFabId ? this.state.lastPlayFabId : (result.Data.Results.length? result.Data.Results[0].PlayFabId : undefined)
            });
        }));
    }

    getAccount = (e: any, playFabIdOverride = undefined) => {
        this.webQuery('getAccount', (async () =>
        {
            return (await Common.Api.getAccountPost(this.state.token, {
                TitleId: this.state.titleId,
                PlayFabId: playFabIdOverride ?? this.state.retrievePlayFabId,
            })).data;
        }),
        ((result) => {
            if(result.Success) {
                this.setState({
                    lastPlayFabId: this.state.retrievePlayFabId,
                    playFabId: this.state.retrievePlayFabId,
                    accountInfo: result.Data.AccountInfo,
                    ban: result.Data.Ban,
                    mute: result.Data.Mute,
                    grants: result.Data.Grants,
                    account: result
                });
            }
            else {
                this.setState({snackbarMessage: `Failed to get account ${playFabIdOverride ?? this.state.retrievePlayFabId}`});
            }
        }));
    };

    getAccountByEosId = (e: any, playFabIdOverride = undefined) => {
        this.webQuery('getAccountByEosId', (async () =>
        {
            return (await Common.Api.getAccountByEosIdPost(this.state.token, {
                TitleId: this.state.titleId,
                EosIdentity: this.state.eosId,
            })).data;
        }),
        ((result) => {
            this.setState({
                retrievePlayFabId: result.Data.AccountInfo.UserInfo?.PlayFabId as string,
                lastPlayFabId: result.Data.AccountInfo.UserInfo?.PlayFabId,
                playFabId: result.Data.AccountInfo.UserInfo?.PlayFabId as string,
                accountInfo: result.Data.AccountInfo,
                ban: result.Data.Ban,
                mute: result.Data.Mute,
                grants: result.Data.Grants,
                account: result
            });
        }));
    };

    clearGrantedState = (e: any, grant: string) => {
        this.webQuery('clearGrantedState', (async () =>
        {
            return (await Common.Api.clearGrantStatePost(this.state.token, {
                TitleId: this.state.titleId,
                PlayFabId: this.state.playFabId,
                Grant: grant,
            })).data;
        }),
        ((result) => {
            this.getAccount(undefined);
        }));
    };

    update = (name: string, e: any) => {
        let value = e.target.value;
        this.setState({[name]: value} as Pick<KickBanState, keyof KickBanState>);
    };

    handleBanReasonChange = (e: any) => {
        this.setState({banReason: e.target.value});
    }

    handleIpAddressChange = (e: any) => {
        this.setState({ipAddressSearch: e.target.value});
    }

    render() {
        if (this.state.playFabId === '' || !this.state.playFabId) {
            let ipBanButton;
            if(this.state.ipAddress?.length){
                const hasIpAddress = this.state.ipBans.find((element: {TitleId: string, IpAddress: string}) => element.TitleId === this.state.titleId && element.IpAddress === this.state.ipAddress);
                ipBanButton =  <div><p><small> {!hasIpAddress ? `IPBan will be associated with PlayFabId ${this.state.lastPlayFabId}`: ''}</small></p><Button
                            color="primary"
                            disabled={this.state.inFlight || !!hasIpAddress}
                            onClick={(e) => this.banIp()}
                        >
                            
                            IPBan {this.state.ipAddress}
                        </Button><Button
                            color="primary"
                            disabled={this.state.inFlight || !hasIpAddress}
                            onClick={(e) => this.unBanIp()}
                        >
                            IPUnBan {this.state.ipAddress}
                        </Button></div>;
                        //
            }
            let rows = [];
            for (let i = 0; i < this.state.searchResults.length; i++) {
                rows.push(
                    <TableRow>
                        <TableCell key={`searchResult${i}`}>
                            <Link
                                href="#"
                                color="secondary"
                                //disabled={this.state.inFlight}
                                onClick={(e) => {
                                    this.setState({
                                        retrievePlayFabId:
                                        this.state.searchResults[i].PlayFabId
                                    });
                                    this.getAccount(e, this.state.searchResults[i].PlayFabId); //sent as a parameter due to delay on setState
                                }}
                            >
                                {this.state.searchResults[i].PlayFabId}
                            </Link>
                        </TableCell>
                        <TableCell>
                            {this.state.searchResults[i].Platform}
                        </TableCell>
                        <TableCell>
                            {' '}
                            {this.state.searchResults[i].DisplayName}
                        </TableCell>
                        <TableCell>
                            {' '}
                            {`${this.state.searchResults[i].Ban && this.state.searchResults[i].Ban.Active ? "Banned" : "No-Ban"}|${this.state.searchResults[i].Mute && this.state.searchResults[i].Mute ? "Muted" : "No-Mute"}`}
                        </TableCell>
                    </TableRow>
                );
            }
            return (
                <form>
                    <div>
                        <br/>
                        <TextField
                            style={{width: '50%'}}
                            inputProps={{minLength: 3, maxLength: 25}}
                            className="textField"
                            id="displayName"
                            label="displayName"
                            value={this.state.displayName}
                            onChange={(e) => this.update('displayName', e)}
                        />
                        <Button
                            color="primary"
                            disabled={this.state.inFlight || this.state.displayName.length < 3}
                            onClick={this.search}
                        >
                            Search
                        </Button>
                    </div>
                    <div>
                        <TextField
                            style={{width: '50%'}}
                            className="textField"
                            id="playFabId"
                            label="playFabId"
                            value={this.state.retrievePlayFabId}
                            onChange={(e) =>
                                this.update('retrievePlayFabId', e)
                            }
                        />
                        <Button
                            color="primary"
                            disabled={this.state.inFlight || !this.state.retrievePlayFabId || this.state.retrievePlayFabId.length < 14}
                            onClick={this.getAccount}
                        >
                            Search
                        </Button>
                    </div>
                    <div>
                        <TextField
                            style={{width: '50%'}}
                            className="textField"
                            id="eosId"
                            label="eosId"
                            value={this.state.eosId}
                            onChange={(e) =>
                                this.update('eosId', e)
                            }
                        />
                        <Button
                            color="primary"
                            disabled={this.state.inFlight || this.state.eosId.length < 14}
                            onClick={this.getAccountByEosId}
                        >
                            Search
                        </Button>
                    </div>
                    <div>
                        <TextField
                            style={{width: '50%'}}
                            className="textField"
                            id="ipAddressSearch"
                            label="ipAddressSearch"
                            value={this.state.ipAddressSearch}
                            onChange={(e) =>
                                this.update('ipAddressSearch', e)
                            }
                        />
                        <Button
                            color="primary"
                            disabled={this.state.inFlight || (!this.state.ipAddressSearch || this.state.ipAddressSearch.length < 8)}
                            onClick={this.searchAccountsWithIpAddress}
                        >
                            Search
                        </Button>
                    </div>
                    <br/>
                    {ipBanButton}
                    <div>
                        <Table size="small">
                            <TableHead>{rows}</TableHead>
                        </Table>
                    </div>
                    <Snackbar
                        open={this.state.snackbarMessage != null}
                        message={this.state.snackbarMessage}
                        autoHideDuration={2000}
                        onClose={() => {
                            this.setState({snackbarMessage: null});
                        }}
                    />
                </form>
            );
        } else {
            let clearGrantButtons: any[] = [];
            if (this.state.grants) {
                Object.keys(this.state.grants).forEach((key) => {
                    if (this.state.grants[key][this.state.titleId]) {
                        clearGrantButtons.push(
                            <Button
                                style={{width: 400}}
                                disabled={this.state.inFlight}
                                color="primary"
                                onClick={(e) => {
                                    this.clearGrantedState(
                                        e,
                                        key
                                    );
                                }}
                            >
                                Clear {key.substr(key.indexOf(':') + 1)} {JSON.stringify(this.state.grants[key][this.state.titleId])}
                            </Button>
                        );
                    }
                });
            }

            const ipAddressesSorted = this.state.account?.Data.IpAddresses ? this.state.account.Data.IpAddresses.sort((a, b) => (new Date(a.LastAssumed as string) < new Date(b.LastAssumed as string)) ? 1 : -1) : [];
            const ipAddressComponents = ipAddressesSorted.map((element) => <MenuItem value={element.IpAddress}>{element.IpAddress} [Assumed {element.TimesAssumed} times {element.FirstAssumed?.substring(0, element.FirstAssumed.indexOf('T')).replace(/-/g, '')}-{element.LastAssumed?.substring(0, element.LastAssumed.indexOf('T')).replace(/-/g, '')}] [{element.Accounts} accounts]</MenuItem>);
            const banComponents = this.state.ban ? GetComponents('', this.state.ban): [];
            const muteComponents = this.state.mute ? GetComponents('', this.state.mute) : [];
            const accountInfoComponents = this.state.accountInfo ? GetComponents('', this.state.accountInfo) : [];
            const accountInfoComponentsSplitLeft: any[] = [];
            const accountInfoComponentsSplitRight: any[] = [];
            for(let i = 1; i < accountInfoComponents.length; i += 2){
                accountInfoComponentsSplitRight.push(accountInfoComponents[i]);
            }
            for(let i = 0; i < accountInfoComponents.length; i += 2){
                accountInfoComponentsSplitLeft.push(accountInfoComponents[i]);
            }

            return (
                <form>
                    <Table>
                        <TableRow>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <br/>
                                <div className="left">
                                    {accountInfoComponentsSplitLeft}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <br/>
                                <div className="left">
                                    {accountInfoComponentsSplitRight}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <br/>
                                <br/>
                                <div className="left">
                                    {banComponents}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <br/>
                                <div>
                                    <TextField
                                        style={{width: 200}}
                                        //number={true}
                                        className="textField"
                                        id="duration"
                                        label="duration [hrs,0=forever]"
                                        value={this.state.duration}
                                        onChange={(e) =>
                                            this.update('duration', e)
                                        }
                                    />
                                    <br/>
                                </div>
                                <div>
                                    <br/>
                                    <InputLabel>reason [user visible]</InputLabel>
                                    <Select
                                        labelId="banReason-select-label"
                                        id="banReason-select"
                                        className="banReasonSelect"
                                        value={this.state.banReason}
                                        onChange={this.handleBanReasonChange}
                                        style={{width: 200}}
                                    >
                                        <MenuItem value={'griefing'}>griefing</MenuItem>
                                        <MenuItem value={'cheating'}>cheating</MenuItem>
                                        <MenuItem value={'offensiveLanguage'}>offensive language</MenuItem>
                                        <MenuItem value={'harassment'}>harassment</MenuItem>
                                        <MenuItem value={'other'}>other [non-localized]</MenuItem>
                                    </Select>
                                    <br/>
                                    {this.state.banReason === 'other' && <TextField
                                        style={{width: 200}}
                                        className="textField"
                                        id="reason"
                                        label="other reason [user visible]"
                                        value={this.state.reason}
                                        onChange={(e) =>
                                            this.update('reason', e)
                                        }
                                    />}
                                </div>
                                <br/>
                                <Button
                                    style={{width: 200}}
                                    color="primary"
                                    disabled={this.state.inFlight || this.state.banReason === '' || (this.state.banReason === 'other' && this.state.reason === '')}
                                    onClick={this.kick}
                                >
                                    Kick-Ban
                                </Button>
                                <Button
                                    style={{width: 200}}
                                    color="primary"
                                    disabled={this.state.inFlight || !this.state.ban || !this.state.ban.Active }
                                    onClick={this.unBan}
                                >
                                    Unban
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <div className="left">
                                    IP addresses
                                </div>
                            </TableCell>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <Select
                                        labelId="ipAddress-select-label"
                                        id="ipAddress-select"
                                        className="ipAddressSelect"
                                        value={this.state.ipAddressSearch}
                                        onChange={this.handleIpAddressChange}
                                        style={{width: 200}}
                                    >
                                       {ipAddressComponents}
                                </Select>
                                <Button
                                    style={{width: 200}}
                                    color="primary"
                                    disabled={this.state.inFlight || !this.state.ipAddressSearch?.length}
                                    onClick={()=>{
                                        this.setState({ipAddressSearch: this.state.ipAddressSearch});
                                        this.searchAccountsWithIpAddress();
                                    }}
                                >
                                    Find IP address accounts
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <div className="left">
                                    {muteComponents}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                <div>
                                    <TextField
                                        style={{width: 200}}
                                        //number={true}
                                        className="textField"
                                        id="muteDuration"
                                        label="duration [hrs,0=forever]"
                                        value={this.state.muteDuration}
                                        onChange={(e) =>
                                            this.update('muteDuration', e)
                                        }
                                    />
                                    <br/>
                                </div>
                                <div>
                                    <TextField
                                        style={{width: 200}}
                                        className="textField"
                                        id="muteReason"
                                        label="muteReason"
                                        value={this.state.muteReason}
                                        onChange={(e) =>
                                            this.update('muteReason', e)
                                        }
                                    />
                                    <br/>
                                </div>
                                <br/>
                                <Button
                                    style={{width: 200}}
                                    color="primary"
                                    disabled={this.state.inFlight || this.state.muteReason === ''}
                                    onClick={this.mute}
                                >
                                    Mute
                                </Button>
                                <Button
                                    style={{width: 200}}
                                    color="primary"
                                    disabled={this.state.inFlight || !this.state.mute || !this.state.mute.Reason}
                                    onClick={this.unMute}
                                >
                                    UnMute
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}
                            >
                                {clearGrantButtons}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{verticalAlign: 'top'}}
                            >
                                <br/>
                                <div className="left">
                                </div>
                            </TableCell>
                            <TableCell
                                style={{width: '50%', verticalAlign: 'top'}}
                            >
                                {/*<br/>
                                <Button
                                    style={{width: 200}}
                                    disabled={
                                        this.state.inFlight ||
                                        this.state.titleId !== '97CAC'
                                    }
                                    color="primary"
                                    onClick={this.resetAccount}
                                >
                                    Reset Account
                                </Button>*/}
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Snackbar
                        open={this.state.snackbarMessage != null}
                        message={this.state.snackbarMessage}
                        autoHideDuration={2000}
                        onClose={() => {
                            this.setState({snackbarMessage: null});
                        }}
                    />
                </form>
            );
        }
    }
}

export default KickBan;
