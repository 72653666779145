import React from 'react';
import './App.css';
import {
    Button,
    Snackbar,
    TextField,
} from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import * as Common from './common';
import * as AxiosApi from './c2-api-admin/api'

const secondsInYear = 31536000;

type StateProps = {
    accountId: string;
    token: string;
    titleId: string;
    campaigns: AxiosApi.InlineResponseDefault1DataCampaigns[],
    state: any;
};

interface StateState extends StateProps {
    snackbarMessage: string | null;
    inFlight: boolean;
};

function getStateFromProps(props: StateProps): StateState {
    return ({
        ...props,
        snackbarMessage: null,
        inFlight: false
    });
}

class State extends React.Component<StateProps, StateState> {
    errorMessage: string = '';

    constructor(props: StateProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: StateProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    async webQuery<QueryResultType>(action: string, query : () => Promise<QueryResultType>, onSuccess: (result: QueryResultType) => void){
        const webAsync = async () => {
            this.errorMessage = '';
            this.setState({inFlight : true});

            try {
                const result = ((await query()) as unknown as {Success: boolean, Error: string});
                if (result.Success) {
                    this.setState({snackbarMessage: `${action} succeeded`});
                    onSuccess(result as unknown as QueryResultType);
                } else {
                    this.setState({
                        snackbarMessage:
                        `${action} failed: ${JSON.stringify(result.Error ?? result)}` 
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage: `${action} failed: ${JSON.stringify(error)}`
                });
            } finally {
                this.setState({inFlight : false});
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: `attempting ${action}`});
    }

    setTitleState = () => {
        this.webQuery('set state', (async () =>
        {
            return (await Common.Api.setStatePost(this.state.token, {
                TitleId: this.state.titleId,
                State: this.state.state[this.state.titleId]})).data;
        }),
        (() => {
        }));
    };

    submitCampaignAvailability = (titleId: string, campaignId: string) => {
        this.webQuery('set campaign availability', (async () =>
        {
            return (await Common.Api.setCampaignAttributesPost(this.state.token, {
                TitleId: this.state.titleId,
                CampaignId: campaignId,
                StartDate: this.state.campaigns.find((element) => element.TitleId === titleId && element.CampaignId === campaignId)?.AvailableBetween.From as number,
                EndDate: this.state.campaigns.find((element) => element.TitleId === titleId && element.CampaignId === campaignId)?.AvailableBetween.Until as number,
            })).data;
        }),
        (() => {
        }));
    };

    setTestAppAccess = (value: any) => {
        let tempState = this.state.state;
        tempState[this.state.titleId].testAppAccess = value;
        this.setState({state: tempState});
    };

    setTransientAccess = (value: any) => {
        let tempState = this.state.state;
        tempState[this.state.titleId].transientAccess = value;
        this.setState({state: tempState});
    };

    setAvailableFrom = (titleId: string, campaignId: string, value: any) => {
        let temp = this.state.campaigns;
        const member =temp.find((element) => element.CampaignId === campaignId && element.TitleId === titleId);
        if(member?.AvailableBetween.From){
            member.AvailableBetween.From = value.getTime() / 1000;
        }
        this.setState({campaigns: temp});
    };

    setAvailableUntil = (titleId: string, campaignId: string, value: any) => {
        let temp = this.state.campaigns;
        const member =temp.find((element) => element.CampaignId === campaignId && element.TitleId === titleId);
        if(member?.AvailableBetween.Until){
            member.AvailableBetween.Until = value.getTime() / 1000;
        }
        this.setState({campaigns: temp});
    };

    render() {
        
    //<div>{JSON.stringify(this.state.campaigns.filter((element:AxiosApi.InlineResponseDefault1Campaigns) => element.TitleId === this.state.titleId))}</div>
    const campaigns = this.state.campaigns.filter((element) => element.TitleId === this.state.titleId).map((element) => 
        <div><br></br><br></br><LocalizationProvider  dateAdapter={AdapterDateFns}>
            <TextField
                            style={{width: '20%'}}
                            inputProps={{minLength: 3, maxLength: 15}}
                            className="textField"
                            id="displayName"
                            disabled={true}
                            label=""
                            value={element.FriendlyName}
                            onChange={(e) => {}}
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <DateTimePicker
            label="Availabile from"
            format="yyyy-MM-dd hh:mm"
            value={
                new Date((element.AvailableBetween?.From ?? ((Date.now() / 1000) - secondsInYear)) as number * 1000)
            }
            onChange={(value) => this.setAvailableFrom(element.TitleId, element.CampaignId, value)}
        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <DateTimePicker
            label="Available until"
            format="yyyy-MM-dd hh:mm"
            value={
                new Date((element.AvailableBetween?.Until ?? ((Date.now() / 1000) + secondsInYear)) as number * 1000) 
            }
            onChange={(value) => this.setAvailableUntil(element.TitleId, element.CampaignId, value)}
        />
        </LocalizationProvider>
    <Button
        color="primary"
        disabled={this.state.inFlight}
        onClick={() => this.submitCampaignAvailability(element.TitleId, element.CampaignId)}
    >
        Set
    </Button></div>);
        if (this.state.titleId === 'B290A') {
            return (
                <form>
                    <br/><LocalizationProvider  dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Test realm available until"
                            format="yyyy-MM-dd hh:mm"
                            value={
                                this.state.state[this.state.titleId]
                                    .testAppAccess
                            }
                            onChange={this.setTestAppAccess}
                        />
                    <Button
                        color="primary"
                        disabled={this.state.inFlight}
                        onClick={this.setTitleState}
                    >
                        Set
                    </Button>
                    {campaigns}
                    <Snackbar
                        open={this.state.snackbarMessage != null}
                        message={this.state.snackbarMessage}
                        autoHideDuration={2000}
                        onClose={() => {
                            this.setState({snackbarMessage: null});
                        }}
                    />
                    </LocalizationProvider>
                </form>
            );
        } else if (
            this.state.titleId === 'EBF8D' ||
            this.state.titleId === '97CAC'
        ) {
            return (
                <form>
                    <br/><LocalizationProvider  dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Transient access available until"
                            format="yyyy/MM/dd hh:mm"
                            value={
                                this.state.state[this.state.titleId]
                                    .transientAccess
                            }
                            onChange={this.setTransientAccess}
                        />
                    <Button
                        color="primary"
                        disabled={this.state.inFlight}
                        onClick={this.setTitleState}
                    >
                        Set
                    </Button>
                    {campaigns}
                    <Snackbar
                        open={this.state.snackbarMessage != null}
                        message={this.state.snackbarMessage}
                        autoHideDuration={2000}
                        onClose={() => {
                            this.setState({snackbarMessage: null});
                        }}
                    /></LocalizationProvider>
                </form>
            );
        } else {
            return (
                <form>
                    <Snackbar
                        open={this.state.snackbarMessage != null}
                        message={this.state.snackbarMessage}
                        autoHideDuration={2000}
                        onClose={() => {
                            this.setState({snackbarMessage: null});
                        }}
                    />
                </form>
            );
        }
    }
}

export default State;
