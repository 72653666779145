import React from 'react';
import './App.css';
import {Button, Snackbar, TextField, Typography} from '@mui/material';
import { Navigate } from "react-router-dom";
import * as Common from './common';
import Whirlpool from './whirlpool';

const validPasswordRegex = RegExp(
    '^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])|(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])|(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])).{8,32}$'
);

const generateHex = (size: number) =>
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('');

type PasswordSetProps = {
    accountId: string;
    token: string;
};

type PasswordSetState = {
    accountId: string;
    password: string;
    passwordConfirm: string;
    token: string;
    snackbarMessage: string | null;
    errorMessage?: string;
    inFlight: boolean;
    redirect: boolean;
};

class Password extends React.Component<PasswordSetProps, PasswordSetState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: PasswordSetProps) {
        super(props);
        this.state = {
            accountId: props.accountId,
            password: '',
            passwordConfirm: '',
            token: props.token,
            inFlight: false,
            snackbarMessage: null,
            redirect: false
        };
    }

    componentWillReceiveProps(props: PasswordSetProps, nextContext: any) {
        this.setState({
            accountId: props.accountId,
            password: '',
            passwordConfirm: '',
            token: props.token,
            inFlight: false,
            snackbarMessage: null,
            redirect: false
        });
    }

    update = (name: any, e: any) => {
        this.setState({[name]: e.target.value} as Pick<PasswordSetState, keyof PasswordSetState>);
    };

    passwordSet = () => {
        if(!validPasswordRegex.test(this.state.password)){
            this.setState({snackbarMessage: 'Insufficient password complexity (upper, lower, number and symbol)'});
            return;
        }
        if(this.state.password !== this.state.passwordConfirm){
            this.setState({snackbarMessage: 'Passwords must match'});
            return;
        }

        let clientId: string | undefined = localStorage.getItem('chivops-clientid') ?? undefined;

        if (!clientId) {
            clientId = generateHex(16);
            localStorage.setItem('chivops-clientid', clientId);
        }

        const passwordSetAsync = async () => {
            this.errorMessage = '';

            this.inFlight = true;
            try {
                const result = await Common.Api.passwordSetPost({
                    ClientId: clientId as string,
                    AccountId: this.state.accountId,
                    Password: Whirlpool(this.state.password) as string,
                    Token: this.state.token
                })

                if (result.data.Success) {
                    this.setState({snackbarMessage: 'passwordSet successful', redirect : true});
                } else {
                    this.setState({
                        snackbarMessage:
                            'passwordSet failed: ' +
                            (result.data.ErrorMessage ? result.data.ErrorMessage : result.data.Error)
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'passwordSet failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        passwordSetAsync();
        this.setState({snackbarMessage: 'passwordSet'});
    };

    render() {
        let redirect = [];
        if(this.state.redirect){
            redirect.push(<Navigate to="/"/>);
        }
        return (
            <form>
                {redirect}
                <br/>
                <div><Typography
                    color='primary'
                    variant='h6'>
                    Set your password to proceed
                </Typography>
                    </div><br/>
                <TextField
                    style={{width: 300}}
                    id="password"
                    type="password"
                    label="password"
                    value={this.state.password}
                    onChange={(e) => this.update('password', e)}
                />
                <br/>
                <TextField
                    style={{width: 300}}
                    id="passwordConfirm"
                    type="password"
                    label="password confirmation"
                    value={this.state.passwordConfirm}
                    onChange={(e) => this.update('passwordConfirm', e)}
                />
                <br/>
                <br/>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.passwordSet}
                >
                    Submit
                </Button>
                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default Password;
