import React from 'react';
import './App.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import NotesIcon from '@mui/icons-material/Notes';
import PersonIcon from '@mui/icons-material/Person';
import GavelIcon from '@mui/icons-material/Gavel';
import GradeIcon from '@mui/icons-material/Grade';
import Broadcast from './Broadcast';
import KickBan from './KickBan';
import IpBan from './IpBan';
import State from './State';
import Motd from './Motd';
import Quotas from './Quotas';
import Admins from './Admins';
import * as Common from './common';
import * as AxiosApi from './c2-api-admin/api'

const TabToTitleId : {[x : number] : string} = {0: '97CAC', 1: 'EBF8D', 2: 'B290A', 3: 'Misc'};

type MainProps = {
    motd: {[x: string] : any};
    state: object;
    permissions: number;
    accountId: string;
    token: string;
    tabValue: number,
    tabTitleValue: number,
    broadcasts: {[x: string] : string}[],
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[],
    campaigns: AxiosApi.InlineResponseDefault1DataCampaigns[],
    admins: object[],
    accountEvents: object[],
    titleEvents: object[],
    accountEventQuotas: object[],
    titleEventQuotas: object[],
    language: { [x: string]: string },
};

class Main extends React.Component<MainProps, MainProps> {

    accountId: string = '';

    constructor(props: MainProps) {
        super(props);
        const stateToSet: MainProps = {
            ...props,
            tabValue: 0,
            tabTitleValue: 0,
        };
        Common.GetLanguages().forEach((language: string) => {
            stateToSet.language[language] = '';
        });
        this.state = stateToSet;
    }

    update = (name : string, e : any) => {
        this.setState({[name]: e.target.value} as Pick<MainProps, keyof MainProps>);
    };

    handleChange = (event : any, newValue : number) => {
        this.setState({tabValue: newValue});
    };

    handleTitleChange = (event: any, newValue: number) => {
        this.setState({tabTitleValue: newValue});
    };

    render() {
        let elements = [''];
        let languages : string[] = [];

        if (this.state.tabTitleValue < 3) {
            this.state.broadcasts.forEach((element: any) => {
                if (!(element.en in elements)) {
                    elements.push(element.en);
                    Object.keys(element).forEach((key) => {
                        if (key !== '_id' && !(languages.indexOf(key) !== -1)) {
                            languages.push(key);
                        }
                    });
                }
            });
            return (
                <>
                    <Paper square className="square" style={{maxHeight: 900, overflow: 'auto'}}>
                        <Tabs
                            style={{maxWidth: '100%'}}
                            value={this.state.tabTitleValue}
                            onChange={this.handleTitleChange}
                            onClick={()=>this.setState({tabValue: this.state.tabValue})}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label=""
                        >
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="DEV [97CAC]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="PROD [EBF8D]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="TEST [B290A]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="ADMINS"
                                disabled={this.state.permissions < 3}
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="QUOTAS"
                                disabled={this.state.permissions < 3}
                            />
                        </Tabs>
                        <Tabs
                            style={{maxWidth: '100%'}}
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label=""
                        >
                            <Tab
                                style={{minWidth: '9.75%'}}
                                icon={<PhoneIcon/>}
                                label="BROADCAST"
                                onClick={() => {
                                    this.setState({tabValue: 0});
                                  }}
                                disabled={this.state.permissions < 1}
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                icon={<NotesIcon/>}
                                label="MOTD"
                                onClick={() => {
                                    this.setState({tabValue: 1});
                                  }}
                                disabled={this.state.permissions < 2}
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                icon={<PersonIcon/>}
                                onClick={() => {
                                    this.setState({tabValue: 2});
                                  }}
                                label="USER"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                icon={<GavelIcon/>}
                                label="IP BANS"
                                onClick={() => {
                                    this.setState({tabValue: 3});
                                  }}
                                disabled={this.state.permissions < 2}
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                icon={<GradeIcon/>}
                                label="STATE"
                                onClick={() => {
                                    this.setState({tabValue: 4});
                                  }}
                                disabled={this.state.permissions < 2}
                            />
                        </Tabs>
                        <div hidden={this.state.tabValue !== 0}>
                            <Broadcast
                                accountId={this.state.accountId}
                                token={this.state.token}
                                broadcasts={this.state.broadcasts}
                                titleId={TabToTitleId[this.state.tabTitleValue]}
                            />
                            <br/>
                        </div>
                        <div hidden={this.state.tabValue !== 1}>
                            <Motd
                                accountId={this.state.accountId}
                                token={this.state.token}
                                motd={
                                    this.state.motd[
                                        TabToTitleId[this.state.tabTitleValue]
                                        ]
                                }
                                titleId={TabToTitleId[this.state.tabTitleValue]}
                            />
                            <br/>
                        </div>
                        <div hidden={this.state.tabValue !== 2}>
                            <KickBan
                                accountId={this.state.accountId}
                                token={this.state.token}
                                ipBans={this.state.ipBans}
                                titleId={TabToTitleId[this.state.tabTitleValue]}
                                mainComponent={this}
                            />
                        </div>
                        <div hidden={this.state.tabValue !== 3}>
                            <IpBan
                                accountId={this.state.accountId}
                                token={this.state.token}
                                ipBans={this.state.ipBans}
                                titleId={TabToTitleId[this.state.tabTitleValue]}
                                mainComponent={this}
                            />
                        </div>
                        <div hidden={this.state.tabValue !== 4}>
                            <State
                                accountId={this.state.accountId}
                                state={this.state.state}
                                campaigns={this.state.campaigns}
                                token={this.state.token}
                                titleId={TabToTitleId[this.state.tabTitleValue]}
                            />
                        </div>
                    </Paper>
                </>
            );
        } else if (this.state.tabTitleValue >= 3) {
            return (
                <>
                    <Paper square className="square" style={{maxHeight: 900, overflow: 'auto'}}>
                        <Tabs
                            style={{maxWidth: '100%'}}
                            value={this.state.tabTitleValue}
                            onChange={this.handleTitleChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label=""
                        >
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="DEV [97CAC]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="PROD [EBF8D]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="TEST [B290A]"
                            />
                            <Tab style={{minWidth: '9.75%'}} label="ADMINS"/>
                            <Tab style={{minWidth: '9.75%'}} label="QUOTAS"/>
                        </Tabs>
                        <div hidden={this.state.tabTitleValue !== 3}>
                            <Admins
                                accountId={this.state.accountId}
                                token={this.state.token}
                                admins={this.state.admins}
                            />
                        </div>
                        <div hidden={this.state.tabTitleValue !== 4}>
                            <Quotas
                                accountId={this.state.accountId}
                                token={this.state.token}
                                accountEvents={this.state.accountEvents}
                                titleEvents={this.state.titleEvents}
                                accountEventQuotas={this.state.accountEventQuotas}
                                titleEventQuotas={this.state.titleEventQuotas}
                            />
                        </div>
                    </Paper>
                </>
            );
        } else if (this.state.tabTitleValue === 4) {
            return (
                <>
                    <Paper square className="square" style={{maxHeight: 900, overflow: 'auto'}}>
                        <Tabs
                            style={{maxWidth: '100%'}}
                            value={this.state.tabTitleValue}
                            onChange={this.handleTitleChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label=""
                        >
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="DEV [97CAC]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="PROD [EBF8D]"
                            />
                            <Tab
                                style={{minWidth: '9.75%'}}
                                label="TEST [B290A]"
                            />
                            <Tab style={{minWidth: '9.75%'}} label="MISC"/>
                        </Tabs>
                        <div hidden={this.state.tabValue !== 0}>
                            <Admins
                                accountId={this.state.accountId}
                                token={this.state.token}
                                admins={this.state.admins}
                            />
                        </div>
                    </Paper>
                </>
            );
        }
    }
}

export default Main;
