import React from 'react';
import './App.css';
import Login from './Login'
import Main from './Main'
import Password from './Password'
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import * as AxiosApi from './c2-api-admin/api'

type AppState = {
    motd: { [x: string]: any };
    state: object;
    permissions: number;
    accountId: string;
    token: string;
    tabValue: number,
    tabTitleValue: number,
    broadcasts: { [x: string]: string }[],
    admins: object[],
    accountEvents: object[],
    titleEvents: object[],
    accountEventQuotas: object[],
    titleEventQuotas: object[],
    language: { [x: string]: string },
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[],
    campaigns: AxiosApi.InlineResponseDefault1DataCampaigns[],
};

type AppProps = {}

const PasswordWrapper = () => {
    const { accountId, token } = useParams();
    return <Password accountId={accountId as string} token={token as string}/>;
};

class RootComponent extends React.Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            accountId: '',
            permissions: 0,
            token: '',
            state: {},
            motd: {},
            broadcasts: [],
            admins: [],
            accountEvents: [],
            titleEvents: [],
            accountEventQuotas: [],
            titleEventQuotas: [],
            tabValue: 0,
            tabTitleValue: 0,
            language: {},
            ipBans: (undefined as unknown) as AxiosApi.InlineResponseDefault1DataIpBans[],
            campaigns: (undefined as unknown) as AxiosApi.InlineResponseDefault1DataCampaigns[]
        };
    }

    render() {
        return (
            <div className="App">
                <link rel="stylesheet"
                      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
                <header className="App-header">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/password/:accountId/:token" element={<PasswordWrapper/>}/>
                            {!this.state.accountId && <Route path="/" element={<Login rootComponent={this}/>}/>}
                            {this.state.accountId && <Route path="/" element={<Main accountId={this.state.accountId} state={this.state.state} permissions={this.state.permissions}
                                                                                     token={this.state.token} broadcasts={this.state.broadcasts} motd={this.state.motd}
                                                                                     admins={this.state.admins}
                                                                                     campaigns={this.state.campaigns}
                                                                                     ipBans={this.state.ipBans}
                                                                                     accountEvents={this.state.accountEvents}
                                                                                     titleEvents={this.state.titleEvents}
                                                                                     accountEventQuotas={this.state.accountEventQuotas}
                                                                                     titleEventQuotas={this.state.titleEventQuotas}
                                                                                     tabValue={this.state.tabValue}
                                                                                     tabTitleValue={this.state.tabTitleValue}
                                                                                     language={this.state.language}/>}/>}
                        </Routes>
                    </BrowserRouter>

                </header>
            </div>);
    }
}

function App() {
    return (
        <RootComponent/>
    );
}

export default App;
