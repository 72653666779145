import React from 'react';
import './App.css';
import {Button, Snackbar} from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import * as Common from './common';
import * as AxiosApi from './c2-api-admin/api'

type IpBanProps = {
    accountId: string;
    token: string;
    titleId: string;
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[];
    mainComponent: any;
};

type IpBanState = {
    accountId: string;
    token: string;
    titleId: string;
    ipBans: AxiosApi.InlineResponseDefault1DataIpBans[];
    snackbarMessage: string | null;
    mainComponent: any;
}

function getStateFromProps(props: IpBanProps): IpBanState {
    return ({
        accountId: props.accountId,
        token: props.token,
        titleId: props.titleId,
        ipBans: props.ipBans,
        snackbarMessage: null,
        mainComponent: props.mainComponent
    });
}


class IpBan extends React.Component<IpBanProps, IpBanState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: IpBanProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: IpBanProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    
    unBanIp = (ipAddress: string) => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.unBanIpAddressPost(this.state.token, {
                    TitleId: this.state.titleId,
                    IpAddress: ipAddress,
                })).data;

                if (result.Success) {
                    this.setState({snackbarMessage: 'unBanIp succeeded', ipBans : this.state.ipBans.filter((element) => (element.IpAddress !== ipAddress || element.TitleId !== this.state.titleId))});
                    this.state.mainComponent.setState({ipBans : this.state.ipBans.filter((element) => (element.IpAddress !== ipAddress || element.TitleId !== this.state.titleId))});
                } else {
                    this.setState({
                        snackbarMessage:
                            'unBan failed: ' +
                            ('error' in result
                                ? JSON.stringify(result.Error)
                                : JSON.stringify(result)),
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage: 'unBan failed: ' + JSON.stringify(error),
                });
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'unbanning'});
    };

    render() {

        const table = [];
        table.push(
            <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>
                    Ip Address
                </TableCell>
                <TableCell>PlayFabIds</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell></TableCell>
            </TableRow>
        );
        this.state.ipBans.forEach((element) => {
            if (element.TitleId === this.state.titleId) {
                table.push(
                    <TableRow>
                    <TableCell>{element.Date}</TableCell>
                    <TableCell>
                        {element.IpAddress}
                    </TableCell>
                    <TableCell>{element.PlayFabIds}</TableCell>
                    <TableCell>{element.Admin}</TableCell>
                    <TableCell>
                        <Button
                                color="primary"
                                disabled={this.inFlight}
                                onClick={(e) => {this.unBanIp(element.IpAddress)}}
                        >Remove IP Ban</Button>
                    </TableCell>
                </TableRow>
                );
            }
        });
        
        return (
            <form>
                <div className="center"><Table>{table}</Table></div>
                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default IpBan;
