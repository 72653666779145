import React from 'react';
import './App.css';
import {Button, Snackbar, TextField} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as Common from './common';

type BroadcastProps = {
    accountId: string;
    token: string;
    titleId: string;
    broadcasts: { [x: string]: string }[];
};

type BroadcastState = {
    snackbarMessage: string | null;
    accountId: string;
    token: string;
    broadcasts: { [x: string]: string }[];
    broadcastId: string;
    titleId: string;
    languages: { [x: string]: string };
};

function getStateFromProps(props: BroadcastProps): BroadcastState {
    let languages: { [x: string]: string } = {};
    Common.GetLanguages().forEach((language: string) => {
        languages[language] = '';
    });

    return ({
        accountId: props.accountId,
        token: props.token,
        broadcasts: props.broadcasts,
        broadcastId: '',
        titleId: props.titleId,
        languages: languages,
        snackbarMessage: ''
    });
}

class Broadcast extends React.Component<BroadcastProps, BroadcastState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: BroadcastProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: BroadcastProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    broadcast = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                let broadcast: { [x: string]: string } = {};
                Common.GetLanguages().forEach((language: string) => {
                    broadcast[language] = this.state.languages[language];
                });

                const result = (await Common.Api.titleBroadcastPost(this.state.token, {
                    Broadcast: broadcast,
                    TitleId: this.state.titleId,
                })).data;

                if (result.Success) {
                    this.setState({snackbarMessage: 'message sent'});
                } else {
                    this.setState({
                        snackbarMessage:
                            'message failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'message failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'broadcasting ' + this.state.languages.en});
    };

    save = () => {
        let found = false;
        let broadcastId = this.state.broadcastId;
        for (let i = 0; i < this.state.broadcasts.length; i++) {
            if (!('_id' in this.state.broadcasts[i])) {
                continue;
            }
            if (this.state.broadcasts[i]._id === this.state.broadcastId) {
                const tempBroadcasts = this.state.broadcasts;
                Common.GetLanguages().forEach((language: string) => {
                    tempBroadcasts[i][language] = this.state.languages[language];
                });
                this.setState({...this.state, broadcasts: tempBroadcasts});
                found = true;
                break;
            }
        }
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            if (!found) {
                broadcastId = Common.RandomHexBytes(24);
            }

            try {

                let broadcast: { [x: string]: string } = {};
                Common.GetLanguages().forEach((language: string) => {
                    broadcast[language] = this.state.languages[language];
                });

                const result = (await Common.Api.titleBroadcastSavePost(this.state.token, {
                    BroadcastId: broadcastId,
                    Broadcast: broadcast,
                })).data;

                if (result.Success) {
                    if (!found) {
                        const broadcastEntry: { [x: string]: string } = {_id: broadcastId};
                        Common.GetLanguages().forEach((language: string) => {
                            broadcastEntry[language] = this.state.languages[language];
                        });
                        this.setState({
                            ...this.state, languages: {...this.state.languages}, broadcastId: broadcastId,
                            broadcasts: [...this.state.broadcasts, broadcastEntry]
                        });
                    }
                    this.setState({snackbarMessage: 'message saved'});
                } else {
                    this.setState({
                        snackbarMessage:
                            'message failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'message failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'saved ' + this.state.languages.en});
    };

    delete = () => {
        if (this.state.broadcastId === '') {
            return;
        }
        for (let i = 0; i < this.state.broadcasts.length; i++) {
            if (!('_id' in this.state.broadcasts[i])) {
                continue;
            }
            if (this.state.broadcasts[i]._id === this.state.broadcastId) {
                this.state.broadcasts.splice(i, 1);
                break;
            }
        }
        let toSet: { [x: string]: string } = {broadcastId: ''};
        Common.GetLanguages().forEach((language: string) => {
            toSet[language] = '';
        });
        this.setState({languages: toSet});

        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.titleBroadcastDeletePost(this.state.token, {
                    BroadcastId: this.state.broadcastId,
                })).data;

                if (result.Success) {
                    this.setState({snackbarMessage: 'message sent'});
                } else {
                    this.setState({
                        snackbarMessage:
                            'message failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'message failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'deleted ' + this.state.languages.en});
    };

    translate = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.translatePost(this.state.token, {
                    Translate: this.state.languages.en,
                    Languages: Common.GetLanguages(),
                })).data;

                const tempState = {...this.state};
                if (result.Success) {
                    Common.GetLanguages().forEach((language: string) => {
                        tempState.languages[language] = (result.Data.Translated as any)[language];
                    });
                    tempState.snackbarMessage = 'translated';
                } else {
                    tempState.snackbarMessage = 'translate failed: ' +
                        ('Error' in result ? result.Error : 'unspecified');
                }
                this.setState(tempState);
            } catch (error) {
                this.setState({
                    snackbarMessage: 'translate failed: ' + error,
                });
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'translating ' + this.state.languages.en});
    };

    update = (name: string, e: any) => {
        this.setState({[name]: e.target.value} as Pick<BroadcastState, keyof BroadcastState>);
    };

    updateLanguage = (name: string, e: any) => {
        const languages = this.state.languages;
        languages[name] = e.target.value;
        this.setState({languages: languages});
    };

    handleDropdownChange = (event: any) => {
        this.setState({broadcastId: event.target.value});
        let toSet: { [x: string]: string } = {};
        if (event.target.value === '') {
            Common.GetLanguages().forEach((language: string) => {
                toSet[language] = '';
            });
            this.setState({languages: toSet});
        } else {
            this.state.broadcasts.forEach((element) => {
                if (element._id === event.target.value) {
                    Common.GetLanguages().forEach((language: string) => {
                        toSet[language] = element[language];
                    });
                    this.setState({languages: toSet});
                }
            });
        }
    };

    render() {
        let items: any[] = [];
        let elements: any[] = [];
        this.state.broadcasts.forEach((element) => {
            if (!(element.en in elements)) {
                items.push(
                    <MenuItem value={element._id}>{element.en}</MenuItem>
                );
                elements.push(element.en);
            }
        });

        let textFields: any[] = [];
        let languages = Common.GetLanguages();
        languages.forEach((language: string) => {
            textFields.push(
                <div>
                    <TextField
                        className="textField"
                        id={language}
                        label={language}
                        inputProps={{maxLength: 280}}
                        value={this.state.languages[language]}
                        onChange={(e) => this.updateLanguage(language, e)}
                    />
                    <br/>
                </div>
            );
        });

        return (
            <form>
                <br/>
                <Select
                    labelId="broadcast-select-label"
                    id="broadcast-select"
                    className="broadcastSelect"
                    value={this.state.broadcastId}
                    onChange={this.handleDropdownChange}
                    style={{width: 550}}
                >
                    <MenuItem value={''}/>
                    {items}
                </Select>
                <br/>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.broadcast}
                >
                    Send
                </Button>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.save}
                >
                    Save
                </Button>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.delete}
                >
                    Delete
                </Button>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.translate}
                >
                    Translate
                </Button>
                <br/>
                <br/>
                {textFields}

                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default Broadcast;
