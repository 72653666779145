import React from 'react';
import './App.css';
import {Button, Snackbar, TextField} from '@mui/material';
import * as Common from './common';
import Whirlpool from './whirlpool';

const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

/*const validPasswordRegex = RegExp(
    '^(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])|(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])|(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|])).{8,32}$'
);*/

const generateHex = (size: number) =>
    [...Array(size)]
        .map(() => Math.floor(Math.random() * 16).toString(16))
        .join('');

type LoginProps = {
    rootComponent: any;
};

type LoginState = {
    email: string;
    password: string;
    snackbarMessage: string | null;
    errorMessage?: string;
    inFlight: boolean;
    rootComponent: any;
};

class Login extends React.Component<LoginProps, LoginState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            rootComponent: props.rootComponent,
            email: '',
            password: '',
            inFlight: false,
            snackbarMessage: null,
        };
    }

    update = (name: any, e: any) => {
        this.setState({[name]: e.target.value} as Pick<LoginState, keyof LoginState>);
    };

    login = () => {
        let clientId: string | undefined = localStorage.getItem('chivops-clientid') ?? undefined;

        if (!clientId) {
            clientId = generateHex(16);
            localStorage.setItem('chivops-clientid', clientId);
        }
        const loginAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const api = Common.Api;
                const result = (await api.adminLoginPost({
                    ClientId: clientId,
                    Email: this.state.email,
                    Password: Whirlpool(this.state.password)
                })).data;

                if (result.Success) {
                    this.setState({snackbarMessage: 'login successful'});
                    if (result.Success) {
                        this.state.rootComponent.setState({
                            accountId: result.Data.AccountId,
                            permissions: result.Data.Permissions,
                            token: result.Data.Token,
                            state: result.Data.State,
                            broadcasts: result.Data.Broadcasts,
                            ipBans: result.Data.IpBans,
                            motd: result.Data.Motd,
                            admins: result.Data.Admins,
                            accountEvents: result.Data.AccountEvents,
                            titleEvents: result.Data.TitleEvents,
                            accountEventQuotas: result.Data.AccountEventQuotas,
                            titleEventQuotas: result.Data.TitleEventQuotas,
                            campaigns: result.Data.Campaigns
                        });
                    }
                } else {
                    if (
                        result.Error === 'AuthenticationRequired'
                    ) {
                        this.setState({
                            snackbarMessage:
                                'check inbox for authentication email',
                        });
                    } else {
                        this.setState({
                            snackbarMessage:
                                'login failed: ' +
                                (result.ErrorMessage ? result.ErrorMessage : result.Error)
                        });
                    }
                }
            } catch (error) {
                this.setState({snackbarMessage: 'login failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        loginAsync();
        this.setState({snackbarMessage: 'login'});
    };

    passwordReset = () => {
        if (!validEmailRegex.test(this.state.email)) {
            this.setState({snackbarMessage: 'invalid email'});
            return;
        }
        const passwordResetAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.passwordRequestResetPost({
                    Email: this.state.email
                })).data;

                if (result.Success) {
                    this.setState({
                        snackbarMessage:
                            'password reset request successful, check your email',
                    });
                } else {
                    this.setState({
                        snackbarMessage: result.ErrorMessage ? result.ErrorMessage : (
                            'password reset request failed: ' +
                            ('Error' in result ? result.Error : 'unspecified')),
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage: 'password reset request failed: ' + JSON.stringify(error),
                });
            } finally {
                this.inFlight = false;
            }
        };
        passwordResetAsync();
        this.setState({snackbarMessage: 'password reset request ' + this.state.email});
    };

    render() {
        return (
            <form>
                <TextField
                    style={{width: 300}}
                    id="email"
                    label="email"
                    value={this.state.email}
                    onChange={(e) => this.update('email', e)}
                />
                <br/>
                <br/>
                <TextField
                    style={{width: 300}}
                    id="password"
                    type="password"
                    label="password"
                    value={this.state.password}
                    onChange={(e) => this.update('password', e)}
                />
                <br/>
                <br/>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <Button
                                color="primary"
                                disabled={this.inFlight}
                                onClick={this.login}
                            >
                                Login
                            </Button>
                        </td>
                        <td width="150"/>
                        <td>
                            <Button
                                color="primary"
                                disabled={this.inFlight}
                                onClick={this.passwordReset}
                            >
                                Password Reset
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={3000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default Login;
