import * as NanoId from 'nanoid';
import * as Axios from 'axios';
import * as AxiosApi from './c2-api-admin/api';
import * as AxiosClient from './c2-api-admin/index';

const EnableNoisyLogging = process.env.NODE_ENV === 'development';

export const Api: AxiosApi.DefaultApi = new AxiosApi.DefaultApi(
    new AxiosClient.Configuration({
        basePath: `https://${GetBaseUrl()}`,
    }),
);

Axios.default.defaults.validateStatus = function () {
    return true;
};

export const levelNames = [
    'USER',
    'USER + BROADCAST',
    'USER + BROADCAST + MOTD',
    'SUPER USER',
];

export function nameFromLevel(level: number) {
    return levelNames[level];
}

export function getLevelMin() {
    return 0;
}

export function getLevelMax() {
    return 3;
}

export function UnixTime(date = new Date()): number {
    return Math.floor(date.getTime() / 1000);
}

export const GetCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export function Stringify(toStringify: any) {
    if (typeof toStringify != 'object' && Array.isArray(toStringify)) {
        return toStringify;
    }
    if (toStringify instanceof Error) {
        const plainObject: any = {};
        Object.getOwnPropertyNames(toStringify).forEach(function (key) {
            plainObject[key] = toStringify[key];
        });
        toStringify = plainObject;
    }

    return JSON.stringify(toStringify, GetCircularReplacer(), '\t');
}

export function GetLanguages() {
    return ['en', 'fr', 'it', 'de', 'es', 'pt', 'ru', 'pl', 'zh', 'ko'];
}

export function GetBaseUrl() {
    return 'v4.a.api.c2.tornbanner.io';
}

export function Log(text: string) {
    console.log(
        typeof text == 'object' ? JSON.stringify(text, null, '\t') : text,
    );
}

export function LogNoisy(text: string) {
    if (EnableNoisyLogging) {
        console.log(
            typeof text == 'object' ? JSON.stringify(text, null, '\t') : text,
        );
    }
}

export function RandomHexBytes(length = 16): string {
    const generator = NanoId.customAlphabet('0123456789ABCDEF', length);
    return generator();
}
