import React from 'react';
import './App.css';
import MenuItem from '@mui/material/MenuItem';
import {Button, Snackbar, TextField} from '@mui/material';
import Select from '@mui/material/Select';
import * as Common from './common';

type QuotasProps = {
    accountId: string;
    token: string;
    accountEvents: any[];
    titleEvents: any[];
    accountEventQuotas: any;
    titleEventQuotas: any;
};

type QuotasState = {
    accountEvents: any[];
    titleEvents: any[];
    accountEventQuotas: any;
    titleEventQuotas: any;
    snackbarMessage: string | null;
    token: string;
    selected: string;
};

function getStateFromProps(props: QuotasProps): QuotasState {
    return ({
        token: props.token,
        accountEvents: props.accountEvents,
        titleEvents: props.titleEvents,
        accountEventQuotas: props.accountEventQuotas,
        titleEventQuotas: props.titleEventQuotas,
        snackbarMessage: '',
        selected: ''
    });
}

class Quotas extends React.Component<QuotasProps, QuotasState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: QuotasProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: QuotasProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    save = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.setQuotasPost(this.state.token, {
                    AccountEventQuotas: this.state.accountEventQuotas,
                    TitleEventQuotas: this.state.titleEventQuotas
                })).data;
                if (result.Success) {
                    const stateToSet = {...this.state};
                    stateToSet.snackbarMessage = 'saved';
                    this.setState(stateToSet);
                } else {
                    this.setState({
                        snackbarMessage:
                            'quota update failed: ' +
                            ('error' in result
                                ? JSON.stringify(result.Error)
                                : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage:
                        'quota update failed: ' + Common.Stringify(error),
                });
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'setting quotas'});
    };

    addQuota = () => {
        let splitSelected: any = this.state.selected.split(':');
        if (splitSelected[0] === 'account') {
            if (!this.state.accountEventQuotas[splitSelected[1]])
                this.setState({accountEventQuotas: {...this.state.accountEventQuotas, ...{[splitSelected[1]]: 100000}}});
        }
        if (splitSelected[0] === 'title') {
            if (!this.state.titleEventQuotas[splitSelected[1]])
                this.setState({titleEventQuotas: {...this.state.titleEventQuotas, ...{[splitSelected[1]]: 100000}}});
        }
    }

    handleDropdownChange = (e: any) => {
        this.setState({selected: e.target.value});
    }

    render() {
        let items: any[] = [];
        let elements: any[] = [];
        this.state.accountEvents.sort((a, b) => (a.event > b.event) ? 1 : -1);
        this.state.accountEvents.forEach((element) => {
            if (!(element in elements)) {
                items.push(
                    <MenuItem
                        value={`account:${element.event}`}>{`account:${element.event} [${element.occurrences}]`}</MenuItem>
                );
                elements.push(element);
            }
        });
        this.state.titleEvents.sort((a, b) => (a.event > b.event) ? 1 : -1);
        this.state.titleEvents.forEach((element) => {
            if (!(element in elements)) {
                items.push(
                    <MenuItem
                        value={`title:${element.event}`}>{`title:${element.event} [${element.occurrences}]`}</MenuItem>
                );
                elements.push(element);
            }
        });
        let quotaItems: any[] = [];
        let sortedAccountKeys = Object.keys(this.state.accountEventQuotas);
        sortedAccountKeys.sort((a, b) => (a > b) ? 1 : -1);
        sortedAccountKeys.forEach((key: string) => {
            quotaItems.push(
                <div>
                    <br/><TextField
                    style={{width: '50%'}}
                    type='number'
                    inputProps={{minLength: 3, maxLength: 25}}
                    className="textField"
                    id={`account:${key}`}
                    label={`account:${key}`}
                    value={this.state.accountEventQuotas[key]}
                    onChange={(e) => {
                        let toSet = {...this.state.accountEventQuotas};
                        toSet[key] = e.target.value
                        this.setState({accountEventQuotas: toSet});
                    }}
                /><Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={() => {
                        let toSet = {...this.state.accountEventQuotas};
                        delete toSet[key];
                        this.setState({accountEventQuotas: toSet})
                    }}
                >
                    Delete
                </Button>
                </div>
            );
        });
        let sortedTitleKeys = Object.keys(this.state.titleEventQuotas);
        sortedTitleKeys.sort((a, b) => (a > b) ? 1 : -1);
        sortedTitleKeys.forEach((key) => {
            quotaItems.push(
                <div>
                    <br/><TextField
                    style={{width: '50%'}}
                    type='number'
                    inputProps={{minLength: 3, maxLength: 25}}
                    className="textField"
                    id={`title:${key}`}
                    label={`title:${key}`}
                    value={this.state.titleEventQuotas[key]}
                    onChange={(e) => {
                        let toSet = {...this.state.titleEventQuotas};
                        toSet[key] = e.target.value
                        this.setState({titleEventQuotas: toSet});
                    }}
                /><Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={() => {
                        let toSet = {...this.state.titleEventQuotas};
                        delete toSet[key];
                        this.setState({titleEventQuotas: toSet})
                    }}
                >
                    Delete
                </Button>
                </div>
            );
        });
        return (
            <form>
                <div>
                    <br/>
                    Per-buildId event quotas
                    <br/>
                    <Select
                        labelId="broadcast-select-label"
                        id="broadcast-select"
                        className="broadcastSelect"
                        value={this.state.selected}
                        onChange={this.handleDropdownChange}
                        style={{width: 550}}
                    >
                        <MenuItem value={''}/>
                        {items}
                    </Select>
                    <Button
                        color="primary"
                        disabled={this.inFlight}
                        onClick={this.addQuota}
                    >
                        Add
                    </Button>
                    <Button
                        color="primary"
                        disabled={this.inFlight}
                        onClick={this.save}
                    >
                        Save
                    </Button>
                    {quotaItems}
                </div>
                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                /><br/><br/>
            </form>
        );
    }
}

export default Quotas;
