/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountResetRequestBody
 */
export interface AccountResetRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AccountResetRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResetRequestBody
     */
    'PlayFabId': string;
}
/**
 * 
 * @export
 * @interface AdminApproveRequestBody
 */
export interface AdminApproveRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminApproveRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface AdminAuditRequestBody
 */
export interface AdminAuditRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminAuditRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface AdminDeactivateRequestBody
 */
export interface AdminDeactivateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminDeactivateRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface AdminInviteRequestBody
 */
export interface AdminInviteRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminInviteRequestBody
     */
    'Email': string;
    /**
     * 
     * @type {number}
     * @memberof AdminInviteRequestBody
     */
    'Permissions': number;
}
/**
 * 
 * @export
 * @interface AdminInviteResendRequestBody
 */
export interface AdminInviteResendRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminInviteResendRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface AdminLoginRequestBody
 */
export interface AdminLoginRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminLoginRequestBody
     */
    'Password': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLoginRequestBody
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof AdminLoginRequestBody
     */
    'ClientId'?: string;
}
/**
 * 
 * @export
 * @interface AdminRemoveRequestBody
 */
export interface AdminRemoveRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminRemoveRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface AdminSetPermissionsRequestBody
 */
export interface AdminSetPermissionsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AdminSetPermissionsRequestBody
     */
    'Email': string;
    /**
     * 
     * @type {number}
     * @memberof AdminSetPermissionsRequestBody
     */
    'Permissions': number;
}
/**
 * 
 * @export
 * @interface AnalyticsQueriesRequestBody
 */
export interface AnalyticsQueriesRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsQueriesRequestBody
     */
    'Type': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsQueriesRequestBody
     */
    'BuildId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsQueriesRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsQueriesRequestBody
     */
    'Key': string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsQueriesRequestBody
     */
    'Limit': number;
    /**
     * 
     * @type {object}
     * @memberof AnalyticsQueriesRequestBody
     */
    'Parameters': object;
}
/**
 * 
 * @export
 * @interface AuthenticateClientIdRequestBody
 */
export interface AuthenticateClientIdRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateClientIdRequestBody
     */
    'AccountId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateClientIdRequestBody
     */
    'ClientId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateClientIdRequestBody
     */
    'Token': string;
}
/**
 * 
 * @export
 * @interface BanIpAddressRequestBody
 */
export interface BanIpAddressRequestBody {
    /**
     * 
     * @type {string}
     * @memberof BanIpAddressRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof BanIpAddressRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof BanIpAddressRequestBody
     */
    'IpAddress': string;
    /**
     * 
     * @type {string}
     * @memberof BanIpAddressRequestBody
     */
    'Notes': string;
}
/**
 * 
 * @export
 * @interface ClearGrantStateRequestBody
 */
export interface ClearGrantStateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ClearGrantStateRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof ClearGrantStateRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof ClearGrantStateRequestBody
     */
    'Grant': string;
}
/**
 * 
 * @export
 * @interface GetAccountByEosIdRequestBody
 */
export interface GetAccountByEosIdRequestBody {
    /**
     * 
     * @type {string}
     * @memberof GetAccountByEosIdRequestBody
     */
    'EosIdentity': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountByEosIdRequestBody
     */
    'TitleId': string;
}
/**
 * 
 * @export
 * @interface GetAccountRequestBody
 */
export interface GetAccountRequestBody {
    /**
     * 
     * @type {string}
     * @memberof GetAccountRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountRequestBody
     */
    'TitleId': string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault
 */
export interface InlineResponseDefault {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault
     */
    'Success': boolean;
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault
     */
    'Data': object;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault
     */
    'ErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault1
 */
export interface InlineResponseDefault1 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault1
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1
     */
    'ErrorMessage'?: string;
    /**
     * 
     * @type {InlineResponseDefault1Data}
     * @memberof InlineResponseDefault1
     */
    'Data': InlineResponseDefault1Data;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault1Data
 */
export interface InlineResponseDefault1Data {
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1Data
     */
    'AccountId'?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault1Data
     */
    'State'?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1Data
     */
    'Token'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponseDefault1Data
     */
    'Permissions'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'Admins'?: Array<any>;
    /**
     * 
     * @type {Array<InlineResponseDefault1DataIpBans>}
     * @memberof InlineResponseDefault1Data
     */
    'IpBans'?: Array<InlineResponseDefault1DataIpBans>;
    /**
     * 
     * @type {Array<InlineResponseDefault1DataCampaigns>}
     * @memberof InlineResponseDefault1Data
     */
    'Campaigns'?: Array<InlineResponseDefault1DataCampaigns>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'Broadcasts'?: Array<any>;
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault1Data
     */
    'Motd'?: object;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'AccountEvents'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'TitleEvents'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'AccountEventQuotas'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineResponseDefault1Data
     */
    'TitleEventQuotas'?: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault1DataAvailableBetween
 */
export interface InlineResponseDefault1DataAvailableBetween {
    /**
     * 
     * @type {number}
     * @memberof InlineResponseDefault1DataAvailableBetween
     */
    'From': number;
    /**
     * 
     * @type {number}
     * @memberof InlineResponseDefault1DataAvailableBetween
     */
    'Until': number;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault1DataCampaigns
 */
export interface InlineResponseDefault1DataCampaigns {
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataCampaigns
     */
    'CampaignId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataCampaigns
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataCampaigns
     */
    'FriendlyName': string;
    /**
     * 
     * @type {InlineResponseDefault1DataAvailableBetween}
     * @memberof InlineResponseDefault1DataCampaigns
     */
    'AvailableBetween': InlineResponseDefault1DataAvailableBetween;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault1DataIpBans
 */
export interface InlineResponseDefault1DataIpBans {
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'IpAddress': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'TitleId': string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'PlayFabIds': number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'Admin': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'Date': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault1DataIpBans
     */
    'Notes': string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault2
 */
export interface InlineResponseDefault2 {
    /**
     * 
     * @type {InlineResponseDefault2Data}
     * @memberof InlineResponseDefault2
     */
    'Data': InlineResponseDefault2Data;
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault2
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault2
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault2
     */
    'ErrorMessage'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault2Data
 */
export interface InlineResponseDefault2Data {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponseDefault2Data
     */
    'Result'?: Array<object>;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3
 */
export interface InlineResponseDefault3 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault3
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault3
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault3
     */
    'ErrorMessage'?: string;
    /**
     * 
     * @type {InlineResponseDefault3Data}
     * @memberof InlineResponseDefault3
     */
    'Data': InlineResponseDefault3Data;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3Data
 */
export interface InlineResponseDefault3Data {
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault3Data
     */
    'Grants': object;
    /**
     * 
     * @type {Array<InlineResponseDefault3DataIpAddresses>}
     * @memberof InlineResponseDefault3Data
     */
    'IpAddresses'?: Array<InlineResponseDefault3DataIpAddresses>;
    /**
     * 
     * @type {InlineResponseDefault3DataBan}
     * @memberof InlineResponseDefault3Data
     */
    'Ban': InlineResponseDefault3DataBan;
    /**
     * 
     * @type {InlineResponseDefault3DataMute}
     * @memberof InlineResponseDefault3Data
     */
    'Mute': InlineResponseDefault3DataMute;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfo}
     * @memberof InlineResponseDefault3Data
     */
    'AccountInfo': InlineResponseDefault3DataAccountInfo;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3DataAccountInfo
 */
export interface InlineResponseDefault3DataAccountInfo {
    /**
     * The Http status code. If X-ReportErrorAsSuccess header is set to true, this will report the actual http error code.
     * @type {number}
     * @memberof InlineResponseDefault3DataAccountInfo
     */
    'code'?: number;
    /**
     * The Http status code as a string.
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfo
     */
    'status'?: string;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfo}
     * @memberof InlineResponseDefault3DataAccountInfo
     */
    'UserInfo'?: InlineResponseDefault3DataAccountInfoUserInfo;
}
/**
 * Account details for the user whose information was requested.
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfo {
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoAndroidDeviceInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'AndroidDeviceInfo'?: InlineResponseDefault3DataAccountInfoUserInfoAndroidDeviceInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoAppleAccountInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'AppleAccountInfo'?: InlineResponseDefault3DataAccountInfoUserInfoAppleAccountInfo;
    /**
     * Timestamp indicating when the user account was created
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'Created': string;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoCustomIdInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'CustomIdInfo'?: InlineResponseDefault3DataAccountInfoUserInfoCustomIdInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'FacebookInfo'?: InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoFacebookInstantGamesIdInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'FacebookInstantGamesIdInfo'?: InlineResponseDefault3DataAccountInfoUserInfoFacebookInstantGamesIdInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoGameCenterInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'GameCenterInfo'?: InlineResponseDefault3DataAccountInfoUserInfoGameCenterInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'GoogleInfo'?: InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoIosDeviceInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'IosDeviceInfo'?: InlineResponseDefault3DataAccountInfoUserInfoIosDeviceInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'KongregateInfo'?: InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchAccountInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'NintendoSwitchAccountInfo'?: InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchAccountInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchDeviceIdInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'NintendoSwitchDeviceIdInfo'?: InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchDeviceIdInfo;
    /**
     * OpenID Connect information, if any OpenID Connect accounts have been linked
     * @type {Array<InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo>}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'OpenIdInfo'?: Array<InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo>;
    /**
     * Unique identifier for the user account
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'PlayFabId'?: string;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoPrivateInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'PrivateInfo'?: InlineResponseDefault3DataAccountInfoUserInfoPrivateInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoPsnInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'PsnInfo'?: InlineResponseDefault3DataAccountInfoUserInfoPsnInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoSteamInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'SteamInfo'?: InlineResponseDefault3DataAccountInfoUserInfoSteamInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoTitleInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'TitleInfo'?: InlineResponseDefault3DataAccountInfoUserInfoTitleInfo;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'TwitchInfo'?: InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo;
    /**
     * User account name in the PlayFab service
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'Username'?: string;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoXboxInfo}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfo
     */
    'XboxInfo'?: InlineResponseDefault3DataAccountInfoUserInfoXboxInfo;
}
/**
 * User Android device information, if an Android device has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoAndroidDeviceInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoAndroidDeviceInfo {
    /**
     * Android device ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoAndroidDeviceInfo
     */
    'AndroidDeviceId'?: string;
}
/**
 * Sign in with Apple account information, if an Apple account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoAppleAccountInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoAppleAccountInfo {
    /**
     * Apple subject ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoAppleAccountInfo
     */
    'AppleSubjectId'?: string;
}
/**
 * Custom ID information, if a custom ID has been assigned
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoCustomIdInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoCustomIdInfo {
    /**
     * Custom ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoCustomIdInfo
     */
    'CustomId'?: string;
}
/**
 * User Facebook information, if a Facebook account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo {
    /**
     * Facebook identifier
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo
     */
    'FacebookId'?: string;
    /**
     * Facebook full name
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoFacebookInfo
     */
    'FullName'?: string;
}
/**
 * Facebook Instant Games account information, if a Facebook Instant Games account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoFacebookInstantGamesIdInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoFacebookInstantGamesIdInfo {
    /**
     * Facebook Instant Games ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoFacebookInstantGamesIdInfo
     */
    'FacebookInstantGamesId'?: string;
}
/**
 * User Gamecenter information, if a Gamecenter account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoGameCenterInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoGameCenterInfo {
    /**
     * Gamecenter identifier
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGameCenterInfo
     */
    'GameCenterId'?: string;
}
/**
 * User Google account information, if a Google account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo {
    /**
     * Email address of the Google account
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
     */
    'GoogleEmail'?: string;
    /**
     * Gender information of the Google account
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
     */
    'GoogleGender'?: string;
    /**
     * Google ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
     */
    'GoogleId'?: string;
    /**
     * Locale of the Google account
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
     */
    'GoogleLocale'?: string;
    /**
     * Name of the Google account user
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoGoogleInfo
     */
    'GoogleName'?: string;
}
/**
 * User iOS device information, if an iOS device has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoIosDeviceInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoIosDeviceInfo {
    /**
     * iOS device ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoIosDeviceInfo
     */
    'IosDeviceId'?: string;
}
/**
 * User Kongregate account information, if a Kongregate account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo {
    /**
     * Kongregate ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo
     */
    'KongregateId'?: string;
    /**
     * Kongregate Username
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoKongregateInfo
     */
    'KongregateName'?: string;
}
/**
 * Nintendo Switch account information, if a Nintendo Switch account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchAccountInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchAccountInfo {
    /**
     * Nintendo Switch account subject ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchAccountInfo
     */
    'NintendoSwitchAccountSubjectId'?: string;
}
/**
 * Nintendo Switch device information, if a Nintendo Switch device has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchDeviceIdInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchDeviceIdInfo {
    /**
     * Nintendo Switch Device ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoNintendoSwitchDeviceIdInfo
     */
    'NintendoSwitchDeviceId'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo {
    /**
     * OpenID Connection ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo
     */
    'ConnectionId'?: string;
    /**
     * OpenID Issuer
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo
     */
    'Issuer'?: string;
    /**
     * OpenID Subject
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoOpenIdInfo
     */
    'Subject'?: string;
}
/**
 * Personal information for the user which is considered more sensitive
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoPrivateInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoPrivateInfo {
    /**
     * user email address
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoPrivateInfo
     */
    'Email'?: string;
}
/**
 * User PSN account information, if a PSN account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoPsnInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoPsnInfo {
    /**
     * PSN account ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoPsnInfo
     */
    'PsnAccountId'?: string;
    /**
     * PSN online ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoPsnInfo
     */
    'PsnOnlineId'?: string;
}
/**
 * User Steam information, if a Steam account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoSteamInfo {
    /**
     * what stage of game ownership the user is listed as being in, from Steam
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
     */
    'SteamActivationStatus'?: InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamActivationStatusEnum;
    /**
     * the country in which the player resides, from Steam data
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
     */
    'SteamCountry'?: string;
    /**
     * currency type set in the user Steam account
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
     */
    'SteamCurrency'?: InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamCurrencyEnum;
    /**
     * Steam identifier
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
     */
    'SteamId'?: string;
    /**
     * Steam display name
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoSteamInfo
     */
    'SteamName'?: string;
}

export const InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamActivationStatusEnum = {
    None: 'None',
    ActivatedTitleKey: 'ActivatedTitleKey',
    PendingSteam: 'PendingSteam',
    ActivatedSteam: 'ActivatedSteam',
    RevokedSteam: 'RevokedSteam'
} as const;

export type InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamActivationStatusEnum = typeof InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamActivationStatusEnum[keyof typeof InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamActivationStatusEnum];
export const InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamCurrencyEnum = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byr: 'BYR',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Chf: 'CHF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Crc: 'CRC',
    Cuc: 'CUC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ggp: 'GGP',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Imp: 'IMP',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jep: 'JEP',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mro: 'MRO',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Spl: 'SPL',
    Srd: 'SRD',
    Std: 'STD',
    Svc: 'SVC',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Tvd: 'TVD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vef: 'VEF',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xcd: 'XCD',
    Xdr: 'XDR',
    Xof: 'XOF',
    Xpf: 'XPF',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zwd: 'ZWD'
} as const;

export type InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamCurrencyEnum = typeof InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamCurrencyEnum[keyof typeof InlineResponseDefault3DataAccountInfoUserInfoSteamInfoSteamCurrencyEnum];

/**
 * Title-specific information for the user account
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoTitleInfo {
    /**
     * URL to the player\'s avatar.
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'AvatarUrl'?: string;
    /**
     * timestamp indicating when the user was first associated with this game (this can differ significantly from when the user first registered with PlayFab)
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'Created': string;
    /**
     * name of the user, as it is displayed in-game
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'DisplayName'?: string;
    /**
     * timestamp indicating when the user first signed into this game (this can differ from the Created timestamp, as other events, such as issuing a beta key to the user, can associate the title to the user)
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'FirstLogin'?: string;
    /**
     * boolean indicating whether or not the user is currently banned for a title
     * @type {boolean}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'isBanned'?: boolean;
    /**
     * timestamp for the last user login for this title
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'LastLogin'?: string;
    /**
     * source by which the user first joined the game, if known
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'Origination'?: InlineResponseDefault3DataAccountInfoUserInfoTitleInfoOriginationEnum;
    /**
     * 
     * @type {InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfo
     */
    'TitlePlayerAccount'?: InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount;
}

export const InlineResponseDefault3DataAccountInfoUserInfoTitleInfoOriginationEnum = {
    Organic: 'Organic',
    Steam: 'Steam',
    Google: 'Google',
    Amazon: 'Amazon',
    Facebook: 'Facebook',
    Kongregate: 'Kongregate',
    GamersFirst: 'GamersFirst',
    Unknown: 'Unknown',
    Ios: 'IOS',
    LoadTest: 'LoadTest',
    Android: 'Android',
    Psn: 'PSN',
    GameCenter: 'GameCenter',
    CustomId: 'CustomId',
    XboxLive: 'XboxLive',
    Parse: 'Parse',
    Twitch: 'Twitch',
    ServerCustomId: 'ServerCustomId',
    NintendoSwitchDeviceId: 'NintendoSwitchDeviceId',
    FacebookInstantGamesId: 'FacebookInstantGamesId',
    OpenIdConnect: 'OpenIdConnect',
    Apple: 'Apple',
    NintendoSwitchAccount: 'NintendoSwitchAccount'
} as const;

export type InlineResponseDefault3DataAccountInfoUserInfoTitleInfoOriginationEnum = typeof InlineResponseDefault3DataAccountInfoUserInfoTitleInfoOriginationEnum[keyof typeof InlineResponseDefault3DataAccountInfoUserInfoTitleInfoOriginationEnum];

/**
 * Title player account entity for this user
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount {
    /**
     * Unique ID of the entity.
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount
     */
    'Id': string;
    /**
     * Entity type. See https://docs.microsoft.com/gaming/playfab/features/data/entities/available-built-in-entity-types
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTitleInfoTitlePlayerAccount
     */
    'Type'?: string;
}
/**
 * User Twitch account information, if a Twitch account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo {
    /**
     * Twitch ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo
     */
    'TwitchId'?: string;
    /**
     * Twitch Username
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoTwitchInfo
     */
    'TwitchUserName'?: string;
}
/**
 * User XBox account information, if a XBox account has been linked
 * @export
 * @interface InlineResponseDefault3DataAccountInfoUserInfoXboxInfo
 */
export interface InlineResponseDefault3DataAccountInfoUserInfoXboxInfo {
    /**
     * XBox user ID
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoXboxInfo
     */
    'XboxUserId'?: string;
    /**
     * XBox user sandbox
     * @type {string}
     * @memberof InlineResponseDefault3DataAccountInfoUserInfoXboxInfo
     */
    'XboxUserSandbox'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3DataBan
 */
export interface InlineResponseDefault3DataBan {
    /**
     * The active state of this ban. Expired bans may still have this value set to true but they will have no effect.
     * @type {boolean}
     * @memberof InlineResponseDefault3DataBan
     */
    'Active': boolean;
    /**
     * The unique Ban Id associated with this ban.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'BanId'?: string;
    /**
     * The time when this ban was applied.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'Created'?: string;
    /**
     * The time when this ban expires. Permanent bans do not have expiration date.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'Expires'?: string;
    /**
     * The IP address on which the ban was applied. May affect multiple players.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'IPAddress'?: string;
    /**
     * Unique PlayFab assigned ID of the user on whom the operation will be performed.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'PlayFabId'?: string;
    /**
     * The reason why this ban was applied.
     * @type {string}
     * @memberof InlineResponseDefault3DataBan
     */
    'Reason'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3DataIpAddresses
 */
export interface InlineResponseDefault3DataIpAddresses {
    /**
     * IP address that the user assumed
     * @type {string}
     * @memberof InlineResponseDefault3DataIpAddresses
     */
    'IpAddress': string;
    /**
     * The date first assumed
     * @type {string}
     * @memberof InlineResponseDefault3DataIpAddresses
     */
    'FirstAssumed': string;
    /**
     * The date last assumed
     * @type {string}
     * @memberof InlineResponseDefault3DataIpAddresses
     */
    'LastAssumed': string;
    /**
     * The number of times assumed
     * @type {number}
     * @memberof InlineResponseDefault3DataIpAddresses
     */
    'TimesAssumed': number;
    /**
     * The number of accounts that have assumed this ip address
     * @type {number}
     * @memberof InlineResponseDefault3DataIpAddresses
     */
    'Accounts': number;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault3DataMute
 */
export interface InlineResponseDefault3DataMute {
    /**
     * The time when this mute expires. Permanent mutes do not have expiration date.
     * @type {string}
     * @memberof InlineResponseDefault3DataMute
     */
    'Expiration'?: string;
    /**
     * Unique PlayFab assigned ID of the user on whom the operation will be performed.
     * @type {string}
     * @memberof InlineResponseDefault3DataMute
     */
    'PlayFabId'?: string;
    /**
     * The reason why this mute was applied.
     * @type {string}
     * @memberof InlineResponseDefault3DataMute
     */
    'Reason'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault4
 */
export interface InlineResponseDefault4 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault4
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4
     */
    'ErrorMessage'?: string;
    /**
     * 
     * @type {InlineResponseDefault4Data}
     * @memberof InlineResponseDefault4
     */
    'Data': InlineResponseDefault4Data;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault4Data
 */
export interface InlineResponseDefault4Data {
    /**
     * 
     * @type {Array<InlineResponseDefault4DataResults>}
     * @memberof InlineResponseDefault4Data
     */
    'Results': Array<InlineResponseDefault4DataResults>;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault4DataBan
 */
export interface InlineResponseDefault4DataBan {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault4DataBan
     */
    'Active': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataBan
     */
    'Expires'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataBan
     */
    'Reason': string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault4DataMute
 */
export interface InlineResponseDefault4DataMute {
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataMute
     */
    'Expiration'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataMute
     */
    'Reason': string;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault4DataResults
 */
export interface InlineResponseDefault4DataResults {
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataResults
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataResults
     */
    'Platform': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault4DataResults
     */
    'DisplayName': string;
    /**
     * 
     * @type {InlineResponseDefault4DataBan}
     * @memberof InlineResponseDefault4DataResults
     */
    'Ban'?: InlineResponseDefault4DataBan;
    /**
     * 
     * @type {InlineResponseDefault4DataMute}
     * @memberof InlineResponseDefault4DataResults
     */
    'Mute'?: InlineResponseDefault4DataMute;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault5
 */
export interface InlineResponseDefault5 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault5
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault5
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault5
     */
    'ErrorMessage'?: string;
    /**
     * 
     * @type {InlineResponseDefault5Data}
     * @memberof InlineResponseDefault5
     */
    'Data': InlineResponseDefault5Data;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault5Data
 */
export interface InlineResponseDefault5Data {
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault5Data
     */
    'Translated': object;
}
/**
 * 
 * @export
 * @interface InlineResponseDefault6
 */
export interface InlineResponseDefault6 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponseDefault6
     */
    'Success': boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault6
     */
    'Error'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponseDefault6
     */
    'ErrorMessage'?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineResponseDefault6
     */
    'Data': object;
}
/**
 * 
 * @export
 * @interface KickRequestBody
 */
export interface KickRequestBody {
    /**
     * 
     * @type {string}
     * @memberof KickRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof KickRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof KickRequestBody
     */
    'Reason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KickRequestBody
     */
    'Duration': number;
}
/**
 * 
 * @export
 * @interface MuteRequestBody
 */
export interface MuteRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MuteRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof MuteRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof MuteRequestBody
     */
    'Reason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MuteRequestBody
     */
    'Duration': number;
}
/**
 * 
 * @export
 * @interface PasswordRequestResetRequestBody
 */
export interface PasswordRequestResetRequestBody {
    /**
     * 
     * @type {string}
     * @memberof PasswordRequestResetRequestBody
     */
    'Email': string;
}
/**
 * 
 * @export
 * @interface PasswordSetRequestBody
 */
export interface PasswordSetRequestBody {
    /**
     * 
     * @type {string}
     * @memberof PasswordSetRequestBody
     */
    'AccountId': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordSetRequestBody
     */
    'ClientId': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordSetRequestBody
     */
    'Token': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordSetRequestBody
     */
    'Password': string;
}
/**
 * 
 * @export
 * @interface SearchRequestBody
 */
export interface SearchRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestBody
     */
    'DisplayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestBody
     */
    'IpAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequestBody
     */
    'TitleId': string;
}
/**
 * 
 * @export
 * @interface SetCampaignAttributesRequestBody
 */
export interface SetCampaignAttributesRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SetCampaignAttributesRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof SetCampaignAttributesRequestBody
     */
    'CampaignId': string;
    /**
     * 
     * @type {number}
     * @memberof SetCampaignAttributesRequestBody
     */
    'StartDate': number;
    /**
     * 
     * @type {number}
     * @memberof SetCampaignAttributesRequestBody
     */
    'EndDate': number;
}
/**
 * 
 * @export
 * @interface SetQuotasRequestBody
 */
export interface SetQuotasRequestBody {
    /**
     * 
     * @type {object}
     * @memberof SetQuotasRequestBody
     */
    'AccountEventQuotas': object;
    /**
     * 
     * @type {object}
     * @memberof SetQuotasRequestBody
     */
    'TitleEventQuotas': object;
}
/**
 * 
 * @export
 * @interface SetStateRequestBody
 */
export interface SetStateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SetStateRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {object}
     * @memberof SetStateRequestBody
     */
    'State': object;
}
/**
 * 
 * @export
 * @interface TitleBroadcastDeleteRequestBody
 */
export interface TitleBroadcastDeleteRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TitleBroadcastDeleteRequestBody
     */
    'BroadcastId': string;
}
/**
 * 
 * @export
 * @interface TitleBroadcastRequestBody
 */
export interface TitleBroadcastRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TitleBroadcastRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {object}
     * @memberof TitleBroadcastRequestBody
     */
    'Broadcast': object;
}
/**
 * 
 * @export
 * @interface TitleBroadcastSaveRequestBody
 */
export interface TitleBroadcastSaveRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TitleBroadcastSaveRequestBody
     */
    'BroadcastId': string;
    /**
     * 
     * @type {object}
     * @memberof TitleBroadcastSaveRequestBody
     */
    'Broadcast': object;
}
/**
 * 
 * @export
 * @interface TitleMotdRequestBody
 */
export interface TitleMotdRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TitleMotdRequestBody
     */
    'AccountId'?: string;
    /**
     * 
     * @type {object}
     * @memberof TitleMotdRequestBody
     */
    'Motd': object;
    /**
     * 
     * @type {number}
     * @memberof TitleMotdRequestBody
     */
    'Duration': number;
    /**
     * 
     * @type {object}
     * @memberof TitleMotdRequestBody
     */
    'Link': object;
    /**
     * 
     * @type {object}
     * @memberof TitleMotdRequestBody
     */
    'LinkText'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof TitleMotdRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {object}
     * @memberof TitleMotdRequestBody
     */
    'Platforms': object;
    /**
     * 
     * @type {object}
     * @memberof TitleMotdRequestBody
     */
    'Signature': object;
    /**
     * 
     * @type {number}
     * @memberof TitleMotdRequestBody
     */
    'Date'?: number;
}
/**
 * 
 * @export
 * @interface TranslateRequestBody
 */
export interface TranslateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TranslateRequestBody
     */
    'Translate': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof TranslateRequestBody
     */
    'Languages': Array<any>;
}
/**
 * 
 * @export
 * @interface UnBanIpAddressRequestBody
 */
export interface UnBanIpAddressRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UnBanIpAddressRequestBody
     */
    'TitleId': string;
    /**
     * 
     * @type {string}
     * @memberof UnBanIpAddressRequestBody
     */
    'IpAddress': string;
}
/**
 * 
 * @export
 * @interface UnBanRequestBody
 */
export interface UnBanRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UnBanRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof UnBanRequestBody
     */
    'TitleId': string;
}
/**
 * 
 * @export
 * @interface UnMuteRequestBody
 */
export interface UnMuteRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UnMuteRequestBody
     */
    'PlayFabId': string;
    /**
     * 
     * @type {string}
     * @memberof UnMuteRequestBody
     */
    'TitleId': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AccountResetRequestBody} AccountResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPost: async (Token: string, AccountResetRequestBody: AccountResetRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('accountResetPost', 'Token', Token)
            // verify required parameter 'AccountResetRequestBody' is not null or undefined
            assertParamExists('accountResetPost', 'AccountResetRequestBody', AccountResetRequestBody)
            const localVarPath = `/AccountReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AccountResetRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AccountResetRequestBody} AccountResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResetPost(Token: string, AccountResetRequestBody: AccountResetRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResetPost(Token, AccountResetRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AccountResetRequestBody} AccountResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPost(Token: string, AccountResetRequestBody: AccountResetRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.accountResetPost(Token, AccountResetRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {string} Token 
     * @param {AccountResetRequestBody} AccountResetRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public accountResetPost(Token: string, AccountResetRequestBody: AccountResetRequestBody, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).accountResetPost(Token, AccountResetRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AdminApproveRequestBody} AdminApproveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApprovePost: async (Token: string, AdminApproveRequestBody: AdminApproveRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminApprovePost', 'Token', Token)
            // verify required parameter 'AdminApproveRequestBody' is not null or undefined
            assertParamExists('adminApprovePost', 'AdminApproveRequestBody', AdminApproveRequestBody)
            const localVarPath = `/AdminApprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminApproveRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminAuditRequestBody} AdminAuditRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuditPost: async (Token: string, AdminAuditRequestBody: AdminAuditRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminAuditPost', 'Token', Token)
            // verify required parameter 'AdminAuditRequestBody' is not null or undefined
            assertParamExists('adminAuditPost', 'AdminAuditRequestBody', AdminAuditRequestBody)
            const localVarPath = `/AdminAudit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminAuditRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminDeactivateRequestBody} AdminDeactivateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivatePost: async (Token: string, AdminDeactivateRequestBody: AdminDeactivateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminDeactivatePost', 'Token', Token)
            // verify required parameter 'AdminDeactivateRequestBody' is not null or undefined
            assertParamExists('adminDeactivatePost', 'AdminDeactivateRequestBody', AdminDeactivateRequestBody)
            const localVarPath = `/AdminDeactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminDeactivateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteRequestBody} AdminInviteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitePost: async (Token: string, AdminInviteRequestBody: AdminInviteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminInvitePost', 'Token', Token)
            // verify required parameter 'AdminInviteRequestBody' is not null or undefined
            assertParamExists('adminInvitePost', 'AdminInviteRequestBody', AdminInviteRequestBody)
            const localVarPath = `/AdminInvite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminInviteRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteResendRequestBody} AdminInviteResendRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInviteResendPost: async (Token: string, AdminInviteResendRequestBody: AdminInviteResendRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminInviteResendPost', 'Token', Token)
            // verify required parameter 'AdminInviteResendRequestBody' is not null or undefined
            assertParamExists('adminInviteResendPost', 'AdminInviteResendRequestBody', AdminInviteResendRequestBody)
            const localVarPath = `/AdminInviteResend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminInviteResendRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminLoginRequestBody} AdminLoginRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginPost: async (AdminLoginRequestBody: AdminLoginRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'AdminLoginRequestBody' is not null or undefined
            assertParamExists('adminLoginPost', 'AdminLoginRequestBody', AdminLoginRequestBody)
            const localVarPath = `/AdminLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BaseAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "dummy", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminLoginRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminRemoveRequestBody} AdminRemoveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemovePost: async (Token: string, AdminRemoveRequestBody: AdminRemoveRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminRemovePost', 'Token', Token)
            // verify required parameter 'AdminRemoveRequestBody' is not null or undefined
            assertParamExists('adminRemovePost', 'AdminRemoveRequestBody', AdminRemoveRequestBody)
            const localVarPath = `/AdminRemove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminRemoveRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminSetPermissionsRequestBody} AdminSetPermissionsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSetPermissionsPost: async (Token: string, AdminSetPermissionsRequestBody: AdminSetPermissionsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('adminSetPermissionsPost', 'Token', Token)
            // verify required parameter 'AdminSetPermissionsRequestBody' is not null or undefined
            assertParamExists('adminSetPermissionsPost', 'AdminSetPermissionsRequestBody', AdminSetPermissionsRequestBody)
            const localVarPath = `/AdminSetPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AdminSetPermissionsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnalyticsQueriesRequestBody} AnalyticsQueriesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsQueriesPost: async (AnalyticsQueriesRequestBody: AnalyticsQueriesRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'AnalyticsQueriesRequestBody' is not null or undefined
            assertParamExists('analyticsQueriesPost', 'AnalyticsQueriesRequestBody', AnalyticsQueriesRequestBody)
            const localVarPath = `/AnalyticsQueries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BaseAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "dummy", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AnalyticsQueriesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticateClientIdRequestBody} AuthenticateClientIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateClientIdGet: async (AuthenticateClientIdRequestBody: AuthenticateClientIdRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'AuthenticateClientIdRequestBody' is not null or undefined
            assertParamExists('authenticateClientIdGet', 'AuthenticateClientIdRequestBody', AuthenticateClientIdRequestBody)
            const localVarPath = `/AuthenticateClientId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BaseAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "dummy", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AuthenticateClientIdRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {BanIpAddressRequestBody} BanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banIpAddressPost: async (Token: string, BanIpAddressRequestBody: BanIpAddressRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('banIpAddressPost', 'Token', Token)
            // verify required parameter 'BanIpAddressRequestBody' is not null or undefined
            assertParamExists('banIpAddressPost', 'BanIpAddressRequestBody', BanIpAddressRequestBody)
            const localVarPath = `/BanIpAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(BanIpAddressRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {ClearGrantStateRequestBody} ClearGrantStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearGrantStatePost: async (Token: string, ClearGrantStateRequestBody: ClearGrantStateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('clearGrantStatePost', 'Token', Token)
            // verify required parameter 'ClearGrantStateRequestBody' is not null or undefined
            assertParamExists('clearGrantStatePost', 'ClearGrantStateRequestBody', ClearGrantStateRequestBody)
            const localVarPath = `/ClearGrantState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ClearGrantStateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountByEosIdRequestBody} GetAccountByEosIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByEosIdPost: async (Token: string, GetAccountByEosIdRequestBody: GetAccountByEosIdRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('getAccountByEosIdPost', 'Token', Token)
            // verify required parameter 'GetAccountByEosIdRequestBody' is not null or undefined
            assertParamExists('getAccountByEosIdPost', 'GetAccountByEosIdRequestBody', GetAccountByEosIdRequestBody)
            const localVarPath = `/GetAccountByEosId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(GetAccountByEosIdRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountRequestBody} GetAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPost: async (Token: string, GetAccountRequestBody: GetAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('getAccountPost', 'Token', Token)
            // verify required parameter 'GetAccountRequestBody' is not null or undefined
            assertParamExists('getAccountPost', 'GetAccountRequestBody', GetAccountRequestBody)
            const localVarPath = `/GetAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(GetAccountRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {KickRequestBody} KickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickPost: async (Token: string, KickRequestBody: KickRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('kickPost', 'Token', Token)
            // verify required parameter 'KickRequestBody' is not null or undefined
            assertParamExists('kickPost', 'KickRequestBody', KickRequestBody)
            const localVarPath = `/Kick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(KickRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {MuteRequestBody} MuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mutePost: async (Token: string, MuteRequestBody: MuteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('mutePost', 'Token', Token)
            // verify required parameter 'MuteRequestBody' is not null or undefined
            assertParamExists('mutePost', 'MuteRequestBody', MuteRequestBody)
            const localVarPath = `/Mute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MuteRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordRequestResetRequestBody} PasswordRequestResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequestResetPost: async (PasswordRequestResetRequestBody: PasswordRequestResetRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'PasswordRequestResetRequestBody' is not null or undefined
            assertParamExists('passwordRequestResetPost', 'PasswordRequestResetRequestBody', PasswordRequestResetRequestBody)
            const localVarPath = `/PasswordRequestReset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BaseAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "dummy", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PasswordRequestResetRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordSetRequestBody} PasswordSetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSetPost: async (PasswordSetRequestBody: PasswordSetRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'PasswordSetRequestBody' is not null or undefined
            assertParamExists('passwordSetPost', 'PasswordSetRequestBody', PasswordSetRequestBody)
            const localVarPath = `/PasswordSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BaseAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "dummy", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PasswordSetRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {SearchRequestBody} SearchRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPost: async (Token: string, SearchRequestBody: SearchRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('searchPost', 'Token', Token)
            // verify required parameter 'SearchRequestBody' is not null or undefined
            assertParamExists('searchPost', 'SearchRequestBody', SearchRequestBody)
            const localVarPath = `/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SearchRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetCampaignAttributesRequestBody} SetCampaignAttributesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignAttributesPost: async (Token: string, SetCampaignAttributesRequestBody: SetCampaignAttributesRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('setCampaignAttributesPost', 'Token', Token)
            // verify required parameter 'SetCampaignAttributesRequestBody' is not null or undefined
            assertParamExists('setCampaignAttributesPost', 'SetCampaignAttributesRequestBody', SetCampaignAttributesRequestBody)
            const localVarPath = `/SetCampaignAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SetCampaignAttributesRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetQuotasRequestBody} SetQuotasRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuotasPost: async (Token: string, SetQuotasRequestBody: SetQuotasRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('setQuotasPost', 'Token', Token)
            // verify required parameter 'SetQuotasRequestBody' is not null or undefined
            assertParamExists('setQuotasPost', 'SetQuotasRequestBody', SetQuotasRequestBody)
            const localVarPath = `/SetQuotas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SetQuotasRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetStateRequestBody} SetStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatePost: async (Token: string, SetStateRequestBody: SetStateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('setStatePost', 'Token', Token)
            // verify required parameter 'SetStateRequestBody' is not null or undefined
            assertParamExists('setStatePost', 'SetStateRequestBody', SetStateRequestBody)
            const localVarPath = `/SetState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SetStateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastDeleteRequestBody} TitleBroadcastDeleteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastDeletePost: async (Token: string, TitleBroadcastDeleteRequestBody: TitleBroadcastDeleteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('titleBroadcastDeletePost', 'Token', Token)
            // verify required parameter 'TitleBroadcastDeleteRequestBody' is not null or undefined
            assertParamExists('titleBroadcastDeletePost', 'TitleBroadcastDeleteRequestBody', TitleBroadcastDeleteRequestBody)
            const localVarPath = `/TitleBroadcastDelete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TitleBroadcastDeleteRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastRequestBody} TitleBroadcastRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastPost: async (Token: string, TitleBroadcastRequestBody: TitleBroadcastRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('titleBroadcastPost', 'Token', Token)
            // verify required parameter 'TitleBroadcastRequestBody' is not null or undefined
            assertParamExists('titleBroadcastPost', 'TitleBroadcastRequestBody', TitleBroadcastRequestBody)
            const localVarPath = `/TitleBroadcast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TitleBroadcastRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastSaveRequestBody} TitleBroadcastSaveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastSavePost: async (Token: string, TitleBroadcastSaveRequestBody: TitleBroadcastSaveRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('titleBroadcastSavePost', 'Token', Token)
            // verify required parameter 'TitleBroadcastSaveRequestBody' is not null or undefined
            assertParamExists('titleBroadcastSavePost', 'TitleBroadcastSaveRequestBody', TitleBroadcastSaveRequestBody)
            const localVarPath = `/TitleBroadcastSave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TitleBroadcastSaveRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleMotdRequestBody} TitleMotdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleMotdPost: async (Token: string, TitleMotdRequestBody: TitleMotdRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('titleMotdPost', 'Token', Token)
            // verify required parameter 'TitleMotdRequestBody' is not null or undefined
            assertParamExists('titleMotdPost', 'TitleMotdRequestBody', TitleMotdRequestBody)
            const localVarPath = `/TitleMotd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TitleMotdRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {TranslateRequestBody} TranslateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translatePost: async (Token: string, TranslateRequestBody: TranslateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('translatePost', 'Token', Token)
            // verify required parameter 'TranslateRequestBody' is not null or undefined
            assertParamExists('translatePost', 'TranslateRequestBody', TranslateRequestBody)
            const localVarPath = `/Translate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(TranslateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanIpAddressRequestBody} UnBanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBanIpAddressPost: async (Token: string, UnBanIpAddressRequestBody: UnBanIpAddressRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('unBanIpAddressPost', 'Token', Token)
            // verify required parameter 'UnBanIpAddressRequestBody' is not null or undefined
            assertParamExists('unBanIpAddressPost', 'UnBanIpAddressRequestBody', UnBanIpAddressRequestBody)
            const localVarPath = `/UnBanIpAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UnBanIpAddressRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanRequestBody} UnBanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBanPost: async (Token: string, UnBanRequestBody: UnBanRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('unBanPost', 'Token', Token)
            // verify required parameter 'UnBanRequestBody' is not null or undefined
            assertParamExists('unBanPost', 'UnBanRequestBody', UnBanRequestBody)
            const localVarPath = `/UnBan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UnBanRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnMuteRequestBody} UnMuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unMutePost: async (Token: string, UnMuteRequestBody: UnMuteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'Token' is not null or undefined
            assertParamExists('unMutePost', 'Token', Token)
            // verify required parameter 'UnMuteRequestBody' is not null or undefined
            assertParamExists('unMutePost', 'UnMuteRequestBody', UnMuteRequestBody)
            const localVarPath = `/UnMute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AdminAuthorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Token", configuration)

            if (Token !== undefined && Token !== null) {
                localVarHeaderParameter['Token'] = String(Token);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UnMuteRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AdminApproveRequestBody} AdminApproveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApprovePost(Token: string, AdminApproveRequestBody: AdminApproveRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApprovePost(Token, AdminApproveRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminAuditRequestBody} AdminAuditRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAuditPost(Token: string, AdminAuditRequestBody: AdminAuditRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAuditPost(Token, AdminAuditRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminDeactivateRequestBody} AdminDeactivateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeactivatePost(Token: string, AdminDeactivateRequestBody: AdminDeactivateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeactivatePost(Token, AdminDeactivateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteRequestBody} AdminInviteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvitePost(Token: string, AdminInviteRequestBody: AdminInviteRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvitePost(Token, AdminInviteRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteResendRequestBody} AdminInviteResendRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInviteResendPost(Token: string, AdminInviteResendRequestBody: AdminInviteResendRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInviteResendPost(Token, AdminInviteResendRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminLoginRequestBody} AdminLoginRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLoginPost(AdminLoginRequestBody: AdminLoginRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLoginPost(AdminLoginRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminRemoveRequestBody} AdminRemoveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRemovePost(Token: string, AdminRemoveRequestBody: AdminRemoveRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRemovePost(Token, AdminRemoveRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminSetPermissionsRequestBody} AdminSetPermissionsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSetPermissionsPost(Token: string, AdminSetPermissionsRequestBody: AdminSetPermissionsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSetPermissionsPost(Token, AdminSetPermissionsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnalyticsQueriesRequestBody} AnalyticsQueriesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyticsQueriesPost(AnalyticsQueriesRequestBody: AnalyticsQueriesRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyticsQueriesPost(AnalyticsQueriesRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthenticateClientIdRequestBody} AuthenticateClientIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateClientIdGet(AuthenticateClientIdRequestBody: AuthenticateClientIdRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateClientIdGet(AuthenticateClientIdRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {BanIpAddressRequestBody} BanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banIpAddressPost(Token: string, BanIpAddressRequestBody: BanIpAddressRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banIpAddressPost(Token, BanIpAddressRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {ClearGrantStateRequestBody} ClearGrantStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearGrantStatePost(Token: string, ClearGrantStateRequestBody: ClearGrantStateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearGrantStatePost(Token, ClearGrantStateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountByEosIdRequestBody} GetAccountByEosIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountByEosIdPost(Token: string, GetAccountByEosIdRequestBody: GetAccountByEosIdRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountByEosIdPost(Token, GetAccountByEosIdRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountRequestBody} GetAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountPost(Token: string, GetAccountRequestBody: GetAccountRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountPost(Token, GetAccountRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {KickRequestBody} KickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kickPost(Token: string, KickRequestBody: KickRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kickPost(Token, KickRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {MuteRequestBody} MuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mutePost(Token: string, MuteRequestBody: MuteRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mutePost(Token, MuteRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordRequestResetRequestBody} PasswordRequestResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRequestResetPost(PasswordRequestResetRequestBody: PasswordRequestResetRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRequestResetPost(PasswordRequestResetRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordSetRequestBody} PasswordSetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordSetPost(PasswordSetRequestBody: PasswordSetRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordSetPost(PasswordSetRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {SearchRequestBody} SearchRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPost(Token: string, SearchRequestBody: SearchRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault4>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPost(Token, SearchRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetCampaignAttributesRequestBody} SetCampaignAttributesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCampaignAttributesPost(Token: string, SetCampaignAttributesRequestBody: SetCampaignAttributesRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setCampaignAttributesPost(Token, SetCampaignAttributesRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetQuotasRequestBody} SetQuotasRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setQuotasPost(Token: string, SetQuotasRequestBody: SetQuotasRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setQuotasPost(Token, SetQuotasRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetStateRequestBody} SetStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStatePost(Token: string, SetStateRequestBody: SetStateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStatePost(Token, SetStateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastDeleteRequestBody} TitleBroadcastDeleteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleBroadcastDeletePost(Token: string, TitleBroadcastDeleteRequestBody: TitleBroadcastDeleteRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleBroadcastDeletePost(Token, TitleBroadcastDeleteRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastRequestBody} TitleBroadcastRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleBroadcastPost(Token: string, TitleBroadcastRequestBody: TitleBroadcastRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleBroadcastPost(Token, TitleBroadcastRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastSaveRequestBody} TitleBroadcastSaveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleBroadcastSavePost(Token: string, TitleBroadcastSaveRequestBody: TitleBroadcastSaveRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleBroadcastSavePost(Token, TitleBroadcastSaveRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleMotdRequestBody} TitleMotdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleMotdPost(Token: string, TitleMotdRequestBody: TitleMotdRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleMotdPost(Token, TitleMotdRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {TranslateRequestBody} TranslateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translatePost(Token: string, TranslateRequestBody: TranslateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault5>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translatePost(Token, TranslateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanIpAddressRequestBody} UnBanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unBanIpAddressPost(Token: string, UnBanIpAddressRequestBody: UnBanIpAddressRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unBanIpAddressPost(Token, UnBanIpAddressRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanRequestBody} UnBanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unBanPost(Token: string, UnBanRequestBody: UnBanRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unBanPost(Token, UnBanRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnMuteRequestBody} UnMuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unMutePost(Token: string, UnMuteRequestBody: UnMuteRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponseDefault6>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unMutePost(Token, UnMuteRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} Token 
         * @param {AdminApproveRequestBody} AdminApproveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApprovePost(Token: string, AdminApproveRequestBody: AdminApproveRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminApprovePost(Token, AdminApproveRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminAuditRequestBody} AdminAuditRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAuditPost(Token: string, AdminAuditRequestBody: AdminAuditRequestBody, options?: any): AxiosPromise<InlineResponseDefault2> {
            return localVarFp.adminAuditPost(Token, AdminAuditRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminDeactivateRequestBody} AdminDeactivateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeactivatePost(Token: string, AdminDeactivateRequestBody: AdminDeactivateRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminDeactivatePost(Token, AdminDeactivateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteRequestBody} AdminInviteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvitePost(Token: string, AdminInviteRequestBody: AdminInviteRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminInvitePost(Token, AdminInviteRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminInviteResendRequestBody} AdminInviteResendRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInviteResendPost(Token: string, AdminInviteResendRequestBody: AdminInviteResendRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminInviteResendPost(Token, AdminInviteResendRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminLoginRequestBody} AdminLoginRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoginPost(AdminLoginRequestBody: AdminLoginRequestBody, options?: any): AxiosPromise<InlineResponseDefault1> {
            return localVarFp.adminLoginPost(AdminLoginRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminRemoveRequestBody} AdminRemoveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRemovePost(Token: string, AdminRemoveRequestBody: AdminRemoveRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminRemovePost(Token, AdminRemoveRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {AdminSetPermissionsRequestBody} AdminSetPermissionsRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSetPermissionsPost(Token: string, AdminSetPermissionsRequestBody: AdminSetPermissionsRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.adminSetPermissionsPost(Token, AdminSetPermissionsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnalyticsQueriesRequestBody} AnalyticsQueriesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsQueriesPost(AnalyticsQueriesRequestBody: AnalyticsQueriesRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.analyticsQueriesPost(AnalyticsQueriesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticateClientIdRequestBody} AuthenticateClientIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateClientIdGet(AuthenticateClientIdRequestBody: AuthenticateClientIdRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.authenticateClientIdGet(AuthenticateClientIdRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {BanIpAddressRequestBody} BanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banIpAddressPost(Token: string, BanIpAddressRequestBody: BanIpAddressRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.banIpAddressPost(Token, BanIpAddressRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {ClearGrantStateRequestBody} ClearGrantStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearGrantStatePost(Token: string, ClearGrantStateRequestBody: ClearGrantStateRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.clearGrantStatePost(Token, ClearGrantStateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountByEosIdRequestBody} GetAccountByEosIdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByEosIdPost(Token: string, GetAccountByEosIdRequestBody: GetAccountByEosIdRequestBody, options?: any): AxiosPromise<InlineResponseDefault3> {
            return localVarFp.getAccountByEosIdPost(Token, GetAccountByEosIdRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {GetAccountRequestBody} GetAccountRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPost(Token: string, GetAccountRequestBody: GetAccountRequestBody, options?: any): AxiosPromise<InlineResponseDefault3> {
            return localVarFp.getAccountPost(Token, GetAccountRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {KickRequestBody} KickRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kickPost(Token: string, KickRequestBody: KickRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.kickPost(Token, KickRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {MuteRequestBody} MuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mutePost(Token: string, MuteRequestBody: MuteRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.mutePost(Token, MuteRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordRequestResetRequestBody} PasswordRequestResetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRequestResetPost(PasswordRequestResetRequestBody: PasswordRequestResetRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.passwordRequestResetPost(PasswordRequestResetRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordSetRequestBody} PasswordSetRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordSetPost(PasswordSetRequestBody: PasswordSetRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.passwordSetPost(PasswordSetRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {SearchRequestBody} SearchRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPost(Token: string, SearchRequestBody: SearchRequestBody, options?: any): AxiosPromise<InlineResponseDefault4> {
            return localVarFp.searchPost(Token, SearchRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetCampaignAttributesRequestBody} SetCampaignAttributesRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignAttributesPost(Token: string, SetCampaignAttributesRequestBody: SetCampaignAttributesRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.setCampaignAttributesPost(Token, SetCampaignAttributesRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetQuotasRequestBody} SetQuotasRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuotasPost(Token: string, SetQuotasRequestBody: SetQuotasRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.setQuotasPost(Token, SetQuotasRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {SetStateRequestBody} SetStateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStatePost(Token: string, SetStateRequestBody: SetStateRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.setStatePost(Token, SetStateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastDeleteRequestBody} TitleBroadcastDeleteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastDeletePost(Token: string, TitleBroadcastDeleteRequestBody: TitleBroadcastDeleteRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.titleBroadcastDeletePost(Token, TitleBroadcastDeleteRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastRequestBody} TitleBroadcastRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastPost(Token: string, TitleBroadcastRequestBody: TitleBroadcastRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.titleBroadcastPost(Token, TitleBroadcastRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleBroadcastSaveRequestBody} TitleBroadcastSaveRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleBroadcastSavePost(Token: string, TitleBroadcastSaveRequestBody: TitleBroadcastSaveRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.titleBroadcastSavePost(Token, TitleBroadcastSaveRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {TitleMotdRequestBody} TitleMotdRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleMotdPost(Token: string, TitleMotdRequestBody: TitleMotdRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.titleMotdPost(Token, TitleMotdRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {TranslateRequestBody} TranslateRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translatePost(Token: string, TranslateRequestBody: TranslateRequestBody, options?: any): AxiosPromise<InlineResponseDefault5> {
            return localVarFp.translatePost(Token, TranslateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanIpAddressRequestBody} UnBanIpAddressRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBanIpAddressPost(Token: string, UnBanIpAddressRequestBody: UnBanIpAddressRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.unBanIpAddressPost(Token, UnBanIpAddressRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnBanRequestBody} UnBanRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBanPost(Token: string, UnBanRequestBody: UnBanRequestBody, options?: any): AxiosPromise<InlineResponseDefault> {
            return localVarFp.unBanPost(Token, UnBanRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} Token 
         * @param {UnMuteRequestBody} UnMuteRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unMutePost(Token: string, UnMuteRequestBody: UnMuteRequestBody, options?: any): AxiosPromise<InlineResponseDefault6> {
            return localVarFp.unMutePost(Token, UnMuteRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} Token 
     * @param {AdminApproveRequestBody} AdminApproveRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApprovePost(Token: string, AdminApproveRequestBody: AdminApproveRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApprovePost(Token, AdminApproveRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminAuditRequestBody} AdminAuditRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminAuditPost(Token: string, AdminAuditRequestBody: AdminAuditRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminAuditPost(Token, AdminAuditRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminDeactivateRequestBody} AdminDeactivateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDeactivatePost(Token: string, AdminDeactivateRequestBody: AdminDeactivateRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminDeactivatePost(Token, AdminDeactivateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminInviteRequestBody} AdminInviteRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInvitePost(Token: string, AdminInviteRequestBody: AdminInviteRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInvitePost(Token, AdminInviteRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminInviteResendRequestBody} AdminInviteResendRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminInviteResendPost(Token: string, AdminInviteResendRequestBody: AdminInviteResendRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminInviteResendPost(Token, AdminInviteResendRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminLoginRequestBody} AdminLoginRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminLoginPost(AdminLoginRequestBody: AdminLoginRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminLoginPost(AdminLoginRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminRemoveRequestBody} AdminRemoveRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminRemovePost(Token: string, AdminRemoveRequestBody: AdminRemoveRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminRemovePost(Token, AdminRemoveRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {AdminSetPermissionsRequestBody} AdminSetPermissionsRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminSetPermissionsPost(Token: string, AdminSetPermissionsRequestBody: AdminSetPermissionsRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminSetPermissionsPost(Token, AdminSetPermissionsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnalyticsQueriesRequestBody} AnalyticsQueriesRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public analyticsQueriesPost(AnalyticsQueriesRequestBody: AnalyticsQueriesRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).analyticsQueriesPost(AnalyticsQueriesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticateClientIdRequestBody} AuthenticateClientIdRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticateClientIdGet(AuthenticateClientIdRequestBody: AuthenticateClientIdRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticateClientIdGet(AuthenticateClientIdRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {BanIpAddressRequestBody} BanIpAddressRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public banIpAddressPost(Token: string, BanIpAddressRequestBody: BanIpAddressRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).banIpAddressPost(Token, BanIpAddressRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {ClearGrantStateRequestBody} ClearGrantStateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clearGrantStatePost(Token: string, ClearGrantStateRequestBody: ClearGrantStateRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clearGrantStatePost(Token, ClearGrantStateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {GetAccountByEosIdRequestBody} GetAccountByEosIdRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountByEosIdPost(Token: string, GetAccountByEosIdRequestBody: GetAccountByEosIdRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccountByEosIdPost(Token, GetAccountByEosIdRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {GetAccountRequestBody} GetAccountRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountPost(Token: string, GetAccountRequestBody: GetAccountRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccountPost(Token, GetAccountRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {KickRequestBody} KickRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public kickPost(Token: string, KickRequestBody: KickRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).kickPost(Token, KickRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {MuteRequestBody} MuteRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mutePost(Token: string, MuteRequestBody: MuteRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mutePost(Token, MuteRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordRequestResetRequestBody} PasswordRequestResetRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public passwordRequestResetPost(PasswordRequestResetRequestBody: PasswordRequestResetRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).passwordRequestResetPost(PasswordRequestResetRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordSetRequestBody} PasswordSetRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public passwordSetPost(PasswordSetRequestBody: PasswordSetRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).passwordSetPost(PasswordSetRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {SearchRequestBody} SearchRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchPost(Token: string, SearchRequestBody: SearchRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchPost(Token, SearchRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {SetCampaignAttributesRequestBody} SetCampaignAttributesRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setCampaignAttributesPost(Token: string, SetCampaignAttributesRequestBody: SetCampaignAttributesRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setCampaignAttributesPost(Token, SetCampaignAttributesRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {SetQuotasRequestBody} SetQuotasRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setQuotasPost(Token: string, SetQuotasRequestBody: SetQuotasRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setQuotasPost(Token, SetQuotasRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {SetStateRequestBody} SetStateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setStatePost(Token: string, SetStateRequestBody: SetStateRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setStatePost(Token, SetStateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {TitleBroadcastDeleteRequestBody} TitleBroadcastDeleteRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public titleBroadcastDeletePost(Token: string, TitleBroadcastDeleteRequestBody: TitleBroadcastDeleteRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).titleBroadcastDeletePost(Token, TitleBroadcastDeleteRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {TitleBroadcastRequestBody} TitleBroadcastRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public titleBroadcastPost(Token: string, TitleBroadcastRequestBody: TitleBroadcastRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).titleBroadcastPost(Token, TitleBroadcastRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {TitleBroadcastSaveRequestBody} TitleBroadcastSaveRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public titleBroadcastSavePost(Token: string, TitleBroadcastSaveRequestBody: TitleBroadcastSaveRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).titleBroadcastSavePost(Token, TitleBroadcastSaveRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {TitleMotdRequestBody} TitleMotdRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public titleMotdPost(Token: string, TitleMotdRequestBody: TitleMotdRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).titleMotdPost(Token, TitleMotdRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {TranslateRequestBody} TranslateRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public translatePost(Token: string, TranslateRequestBody: TranslateRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).translatePost(Token, TranslateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {UnBanIpAddressRequestBody} UnBanIpAddressRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unBanIpAddressPost(Token: string, UnBanIpAddressRequestBody: UnBanIpAddressRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unBanIpAddressPost(Token, UnBanIpAddressRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {UnBanRequestBody} UnBanRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unBanPost(Token: string, UnBanRequestBody: UnBanRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unBanPost(Token, UnBanRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} Token 
     * @param {UnMuteRequestBody} UnMuteRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public unMutePost(Token: string, UnMuteRequestBody: UnMuteRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).unMutePost(Token, UnMuteRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


