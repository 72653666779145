import React from 'react';
import './App.css';
import {Button, Checkbox, Snackbar, TextField} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker, DateValidationError, PickerChangeHandlerContext} from '@mui/x-date-pickers';
import * as Common from './common';


function copyLanguageData(dest: any, src: any) {
    let languages = Common.GetLanguages();
    languages.forEach((language: string) => {
        dest[language] = src && language in src ? src[language] : '';
    });
}

type MotdProps = {
    accountId: string;
    token: string;
    titleId: string;
    motd: any;

}

type MotdState = {
    accountId: string;
    token: string;
    titleId: string;
    snackbarMessage: string | null;
    firstSelected: string;
    platformIndex: { [x: string]: number };
    selectedLanguage: string;
    tabValue: number;
    motd: any;
    inMotd: any[];
    platforms: { [x: string]: boolean };
    signature: any;
    link: any;
    linkText: any;
    duration: number;
    date: Date;
}

function getStateFromProps(props: MotdProps): MotdState {
    const stateToSet = {
        firstSelected: 'pc',
        platformIndex: {},
        selectedLanguage: 'en',
        tabValue: 0,
        accountId: props.accountId,
        token: props.token,
        motd: {},
        inMotd: props.motd,
        platforms: {pc: true, xbone: true, ps4: true},
        signature: {},
        link: {},
        linkText: {},
        duration: 0,
        date: new Date(),
        titleId: props.titleId,
        snackbarMessage: null
    };
    const platformIndex: { [x: string]: number } = {};
    for (let i = 0; i < stateToSet.inMotd.length; i++) {
        platformIndex[stateToSet.inMotd[i]._id] = i;
    }
    if (stateToSet.inMotd.length) {
        stateToSet.duration = +stateToSet.inMotd[0].Duration;
        stateToSet.date = new Date(stateToSet.inMotd[0].Time * 1000);
        copyLanguageData(stateToSet.motd, stateToSet.inMotd[0].Motd);
        copyLanguageData(
            stateToSet.signature,
            stateToSet.inMotd[0].Signature
        );
        copyLanguageData(
            stateToSet.linkText,
            stateToSet.inMotd[0].LinkText
        );
        copyLanguageData(stateToSet.link, stateToSet.inMotd[0].Link);
    }
    return (stateToSet);
}

class Motd extends React.Component<MotdProps, MotdState> {
    inFlight: boolean = false;
    errorMessage: string = '';

    constructor(props: MotdProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: MotdProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    setMotd = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.titleMotdPost(this.state.token, {
                    Motd: this.state.motd,
                    TitleId: this.state.titleId,
                    Duration: this.state.duration,
                    Date: Common.UnixTime(this.state.date),
                    Signature: this.state.signature,
                    LinkText: this.state.linkText,
                    Link: this.state.link,
                    Platforms: this.state.platforms,
                })).data;

                if (result.Success) {
                    const stateToSet: any = {...this.state, inMotd: []};
                    Object.keys(stateToSet.platforms).forEach((key) => {
                        if (stateToSet.platforms[key]) {
                            stateToSet.inMotd[
                                stateToSet.platformIndex[key]
                                ] = {
                                Duration: stateToSet.duration,
                                Time: Common.UnixTime(stateToSet.date),
                                Motd: stateToSet.motd,
                                Signature: stateToSet.signature,
                                LinkText: stateToSet.linkText,
                                Link: stateToSet.link
                            };
                        }
                    });
                    stateToSet.snackbarMessage = 'saved';
                    this.setState(stateToSet);
                } else {
                    this.setState({
                        snackbarMessage:
                            'motd update failed: ' +
                            ('error' in result
                                ? JSON.stringify(result.Error)
                                : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage:
                        'motd update failed: ' + Common.Stringify(error),
                });
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        })
        this.setState({snackbarMessage: 'setting motd'});
    };

    update = (name: string, e: any) => {
        let value = e.target.value;
        this.setState({[name]: value} as Pick<MotdState, keyof MotdState>);
    };

    updateMotd = (name: string, e: any) => {
        let value = e.target.value;
        this.setState((prevState) => ({
            motd: {
                ...prevState.motd,
                [name]: value,
            },
        }));
    };

    updateLinkText = (name: string, e: any) => {
        let value = e.target.value;
        this.setState((prevState) => ({
            linkText: {
                ...prevState.linkText,
                [name]: value,
            },
        }));
    };

    updateLink = (name: string, e: any) => {
        let value = e.target.value;
        this.setState((prevState) => ({
            link: {
                ...prevState.link,
                [name]: value,
            },
        }));
    };

    updateSignature = (name: string, e: any) => {
        let value = e.target.value;
        this.setState((prevState) => ({
            signature: {
                ...prevState.signature,
                [name]: value,
            },
        }));
    };

    updateCheckbox = (name: string, e: any) => {
        let value = e.target.checked;

        const stateToSet = {...this.state};
        for (let i = 0; i < stateToSet.inMotd.length; i++) {
            if (stateToSet.platforms[stateToSet.inMotd[i]._id]) {
                stateToSet.duration = +stateToSet.inMotd[i].Duration;
                stateToSet.date = new Date(stateToSet.inMotd[i].Time * 1000);
                copyLanguageData(
                    stateToSet.motd,
                    stateToSet.inMotd[i].Motd
                );
                copyLanguageData(
                    stateToSet.signature,
                    stateToSet.inMotd[i].Signature
                );
                copyLanguageData(
                    stateToSet.link,
                    stateToSet.inMotd[i].Link
                );
                copyLanguageData(
                    stateToSet.linkText,
                    stateToSet.inMotd[i].LinkText
                );
                break;
            }
        }
        this.setState({
            ...stateToSet,
            ...{
                platforms: {
                    ...stateToSet.platforms,
                    [name]: value,
                }
            }
        });
    };

    handleChange = (event: any, newValue: number) => {
        let selectedLanguage = 'en';
        let languages = Common.GetLanguages();
        if (newValue < languages.length) {
            selectedLanguage = languages[newValue];
        }
        this.setState({
            tabValue: newValue,
            selectedLanguage: selectedLanguage,
        });
    };

    setDate = (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
        this.setState({date: value ? new Date(value) : new Date()});
    };

    translate = () => {
        const webAsync = async (stateKey: string) => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.translatePost(this.state.token, {
                    Translate: (this.state as any)[stateKey].en,
                    Languages: Common.GetLanguages(),
                })).data;

                if (result.Success) {
                    const tempState: any = {...this.state};
                    Common.GetLanguages().forEach((language: string) => {
                        tempState[stateKey][language] =
                            (result.Data.Translated as any)[language];
                    });
                    tempState.snackbarMessage = 'translated;'
                    this.setState(tempState);
                } else {
                    this.setState({
                        snackbarMessage:
                            'translate failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({
                    snackbarMessage: 'translate failed: ' + error,
                });
            } finally {
                this.inFlight = false;
            }
        };
        webAsync('motd');
        webAsync('linkText');
        this.setState({snackbarMessage: 'translating'});
    };

    getFirstChecked() {
        for (let i = 0; i < this.state.motd.length; i++) {
            let platform = this.state.motd[i]._id;
            let checked = this.state.platforms[platform];
            if (checked) {
                return i;
            }
        }
        return 0;
    }

    render() {
        let language = this.state.selectedLanguage;
        let languageMotd = language + '_motd';
        let languageLink = language + '_link';
        let languageLinkText = language + '_linkText';
        let languageSignature = language + '_signature';

        let tabs: any[] = [];
        let languages = Common.GetLanguages();
        languages.forEach((language: string) => {
            tabs.push(<Tab style={{minWidth: '9.75%'}} label={language}/>);
        });

        let firstChecked = this.getFirstChecked();

        let platformCheckboxes = [];
        for (let i = 0; i < this.state.inMotd.length; i++) {
            let platform = this.state.inMotd[i]._id;
            let checked = this.state.platforms[platform];
            if (!firstChecked) {
                firstChecked = i;
            }
            platformCheckboxes.push(
                <td>
                    {platform}
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            this.updateCheckbox(platform, e);
                        }}
                        name={platform}
                    />
                </td>
            );
        }

        return (
            <form>
                <br/>

                <div className="center">
                    <table>
                        <tr>
                            {platformCheckboxes}
                            <td>
                                <Button
                                    color="primary"
                                    disabled={this.inFlight}
                                    onClick={this.setMotd}
                                >
                                    Set MOTD
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={this.inFlight}
                                    onClick={this.translate}
                                >
                                    Translate
                                </Button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                    <table>
                        <tr>
                            <td>
                                <Checkbox
                                    checked={this.state.duration !== 0}
                                    onChange={(e) => {
                                        this.setState({
                                            duration: e.target.checked
                                                ? 24
                                                : 0,
                                        });
                                    }}
                                />
                                <TextField
                                    disabled={this.state.duration === 0}
                                    //number={true}
                                    className="textField2"
                                    id="duration"
                                    label="Duration [hrs]"
                                    value={this.state.duration}
                                    onChange={(e) => this.update('duration', e)}
                                />
                            </td>
                            <td/>
                            <td><LocalizationProvider  dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        format="yyyy-MM-dd"
                                        label="Message dated"
                                        value={this.state.date}
                                        onChange={this.setDate}
                                    />
                                    <Button
                                        color="primary"
                                        disabled={this.inFlight}
                                        onClick={() => {
                                            this.setState({date: new Date()});
                                        }}
                                    >
                                        Now
                                    </Button></LocalizationProvider>
                            </td>
                        </tr>
                    </table>
                    <br/>
                </div>

                <div>
                    <Tabs
                        style={{minWidth: '85%'}}
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="standard"
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label=""
                    >
                        {tabs}
                    </Tabs>
                </div>

                <div>
                    <TextField
                        multiline={true}
                        className="textField"
                        id={languageMotd}
                        label={languageMotd}
                        value={this.state.motd[language]}
                        onChange={(e) => this.updateMotd(language, e)}
                    />
                    <br/>
                </div>
                <div>
                    <TextField
                        className="textField"
                        id={languageLink}
                        label={languageLink}
                        value={this.state.link[language]}
                        onChange={(e) => this.updateLink(language, e)}
                    />
                    <br/>
                </div>
                <div>
                    <TextField
                        className="textField"
                        id={languageLinkText}
                        label={languageLinkText}
                        value={this.state.linkText[language]}
                        onChange={(e) => this.updateLinkText(language, e)}
                    />
                    <br/>
                </div>
                <div>
                    <TextField
                        className="textField"
                        id={languageSignature}
                        label={languageSignature}
                        value={this.state.signature[language]}
                        onChange={(e) => this.updateSignature(language, e)}
                    />
                    <br/>
                </div>

                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={2000}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default Motd;
