import React from 'react';
import './App.css';
import {Button, Snackbar, TextField} from '@mui/material';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import * as FileSaver from "file-saver"
import * as Common from './common';


type AdminsState = {
    accountId: string;
    token: string;
    email: string;
    permissions: number;
    admins: any[];
    selected: string;
    snackbarMessage: string | null;
};

type AdminsProps = {
    accountId: string;
    token: string;
    admins: any[];
};

function getStateFromProps(props: AdminsProps): AdminsState {
    return ({
        accountId: props.accountId,
        token: props.token,
        admins: props.admins,
        email: '',
        permissions: 0,
        selected: '',
        snackbarMessage: null
    });
}

class Admins extends React.Component<AdminsProps, AdminsState> {
    errorMessage: string = '';
    inFlight: boolean = false;

    constructor(props: AdminsProps) {
        super(props);
        this.state = getStateFromProps(props);
    }

    componentWillReceiveProps(props: AdminsProps, nextContext: any) {
        this.setState(getStateFromProps(props));
    }

    remove = (element: any) => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;

            try {
                const result = (await Common.Api.adminRemovePost(this.state.token, {
                    Email: element.Email,
                })).data;


                if (result.Success) {
                    this.setState({
                        snackbarMessage: 'remove successful',
                        admins: this.state.admins.filter((admin) => admin.Email !== element.Email)
                    })
                } else {
                    this.setState({
                        snackbarMessage:
                            'remove failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'remove failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({snackbarMessage: 'remove ' + element.Email});
    };

    setPerms = (element: any, level: number) => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.adminSetPermissionsPost(this.state.token, {
                    Email: element.Email,
                    Permissions: level,
                })).data;

                if (result.Success) {
                    element.Permissions = level;
                    this.setState({
                        snackbarMessage: 'set permissions successful',
                    });
                } else {
                    this.setState({
                        snackbarMessage:
                            'set permissions failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'set permissions failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({
            snackbarMessage: 'setting permissions ' + element.Email,
        });
    };

    resend = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.adminInviteResendPost(this.state.token, {
                    Email: this.state.selected,
                })).data;

                if (result.Success) {
                    this.setState({snackbarMessage: 'resend successful'});
                } else {
                    this.setState({
                        snackbarMessage:
                            'resend failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'resend failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({snackbarMessage: 'resend ' + this.state.selected});
    };

    rescind = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.adminRemovePost(this.state.token, {
                    Email: this.state.selected,
                })).data;

                if (result.Success) {
                    this.setState({
                        snackbarMessage: 'rescind successful',
                        admins: this.state.admins.filter((element) => this.state.selected !== element.Email)
                    })
                } else {
                    this.setState({
                        snackbarMessage:
                            'rescind failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'rescind failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({snackbarMessage: 'rescind ' + this.state.selected});
    };

    invite = () => {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.adminInvitePost(this.state.token, {
                    Email: this.state.email, Permissions: this.state.permissions
                })).data;

                if (result.Success) {

                    this.setState({
                        admins: [...this.state.admins, {
                            Email: this.state.email,
                            Permissions: this.state.permissions,
                            Active: false
                        }],
                        snackbarMessage: 'invitation successful'
                    });
                } else {
                    this.setState({
                        snackbarMessage:
                            'invitation failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'invitation failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({snackbarMessage: 'inviting ' + this.state.email});
    };

    update = (name: string, e: any) => {
        this.setState({[name]: e.target.value} as Pick<AdminsState, keyof AdminsState>);
    };

    handleDropdownChange = (event: any) => {
        this.setState({selected: event.target.value});
    };

    handleDropdownPermissionsChange = (event: any) => {
        this.setState({permissions: event.target.value});
    };

    saveFile(e: any, email: string) {
        const webAsync = async () => {
            this.errorMessage = '';
            this.inFlight = true;
            try {
                const result = (await Common.Api.adminAuditPost(this.state.token, {
                    Email: email,
                })).data;

                if (result.Success) {
                    FileSaver.saveAs(
                        new Blob([JSON.stringify(result.Data.Result, null, '\t')]),
                        `${email}.json`
                    );
                    e.preventDefault();

                    this.setState({snackbarMessage: 'audit successful, downloading'});
                } else {
                    this.setState({
                        snackbarMessage:
                            'audit failed: ' +
                            ('Error' in result ? result.Error : 'unspecified'),
                    });
                }
            } catch (error) {
                this.setState({snackbarMessage: 'audit failed: ' + error});
            } finally {
                this.inFlight = false;
            }
        };
        webAsync().then(() => {
        });
        this.setState({snackbarMessage: 'auditing ' + this.state.selected});
    };

    createTable = () => {
        let table = [];
        table.push(
            <TableRow>
                <TableCell>Admins</TableCell>
                <TableCell>
                    Permissions
                </TableCell>
                <TableCell>Remove</TableCell>
            </TableRow>
        );
        this.state.admins.forEach((element) => {
            if (element.Active) {
                table.push(
                    <TableRow>
                        <TableCell>{element.Email}</TableCell>
                        <TableCell>
                            <Button color="primary"
                                    disabled={this.inFlight}
                                    onClick={(e) => {
                                        this.saveFile(e, element.Email);
                                        e.preventDefault();
                                    }}>Audit
                            </Button>

                            <Button
                                color="primary"
                                disabled={
                                    this.inFlight ||
                                    ('Permissions' in element
                                        ? element.Permissions
                                        : Common.getLevelMin()) <= Common.getLevelMin()
                                }
                                onClick={(e) => {
                                    this.setPerms(
                                        element,
                                        element.Permissions - 1
                                    );
                                }}
                            >
                                -
                            </Button>
                            {Common.nameFromLevel(
                                'Permissions' in element
                                    ? element.Permissions
                                    : Common.getLevelMin()
                            )}
                            <Button
                                color="primary"
                                disabled={
                                    this.inFlight ||
                                    ('Permissions' in element
                                        ? element.Permissions
                                        : Common.getLevelMin()) >= Common.getLevelMax()
                                }
                                onClick={(e) => {
                                    this.setPerms(
                                        element,
                                        element.Permissions + 1
                                    );
                                }}
                            >
                                +
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Button
                                color="primary"
                                disabled={this.inFlight}
                                onClick={() => {
                                    this.remove(element);
                                }}
                            >
                                x
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            }
        });
        return table;
    };

    render() {
        let items: any[] = [];
        let elements = [''];
        this.state.admins.forEach((element) => {
            if (!(element.Email in elements) && !element.Active) {
                items.push(
                    <MenuItem value={element.Email}>{element.Email}</MenuItem>
                );
                elements.push(element.Email);
            }
        });

        const permissions = [];
        for (let i = Common.getLevelMin(); i <= Common.getLevelMax(); i++) {
            permissions.push(<MenuItem value={i}>{Common.nameFromLevel(i)}</MenuItem>);
        }

        return (
            <form>
                <br/><TextField
                style={{width: 400}}
                className="textField"
                id="email"
                label="email"
                value={this.state.email}
                onChange={(e: any) =>
                    this.update('email', e)
                }
            /><br/>
                <Select
                    labelId="select-label"
                    id="select"
                    style={{width: 250}}
                    className="select"
                    value={this.state.permissions}
                    onChange={this.handleDropdownPermissionsChange}
                >
                    {permissions}
                </Select>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.invite}
                >
                    Invite
                </Button>
                <br/><br/>
                <Select
                    labelId="select-label"
                    id="select"
                    className="select"
                    value={this.state.selected}
                    onChange={this.handleDropdownChange}
                >
                    {items}
                </Select><br/>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.rescind}
                >
                    Rescind
                </Button>
                <Button
                    color="primary"
                    disabled={this.inFlight}
                    onClick={this.resend}
                >
                    Resend
                </Button>
                <br/>
                <br/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>{this.createTable()}</TableHead>
                    </Table>
                </TableContainer>
                <Snackbar
                    open={this.state.snackbarMessage != null}
                    message={this.state.snackbarMessage}
                    autoHideDuration={1500}
                    onClose={() => {
                        this.setState({snackbarMessage: null});
                    }}
                />
            </form>
        );
    }
}

export default Admins;
